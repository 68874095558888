import React, { useState, useRef, useMemo, useContext } from "react";
import download from "@/assets/img/download.svg";
import menuicon from "@/assets/img/menu.svg";
import barcharticon from "@/assets/img/graphCharticon.svg";
import linecharticon from "@/assets/img/lineCharticon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { CSVLink } from "react-csv";
import SavingLineChartPart from "./savings-line-chart.part";
import SavingBarGraphPart from "./saving-bar-graph.part";
import { useIdleGraphStatistics } from "@/services/saving/getIdleGraphStatistics.repo";
import { Spinner } from "react-bootstrap";
import BarGraphErrorStates from "@/components/States/BarGraphErrorStates";
import styles from "./savings.module.css";
import { parse } from "date-fns";
import { SavingContext } from "@/page-components/savings/components/saving-context-file.part";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";

const sortDataByMonth = (data) => {
  return data?.slice().sort((a, b) => {
    const monthA = parse(a.label, "MMM", new Date());
    const monthB = parse(b.label, "MMM", new Date());
    return monthA - monthB;
  });
};

const SavingAWSIdleLineChart = ({ service, toDate, fromDate, name }) => {
  const [chartType, setChartType] = useState("bar");
  const csvLinkRef = useRef(null);

  const colorMap = {
    AWS: "#F90",
    Azure: "#1F78B4",
    GCP: "#0F9D58",
  };
  const context = useContext(SavingContext);

  const query = useMemo(() => {
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );

    return {
      service,
      toDate,
      fromDate,
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectRg?.length
          ? context?.selectRg.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        tags: tags.length ? tags : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectRegions,
    context?.selectRg,
    context?.selectedTags,
    fromDate,
    service,
    toDate,
  ]);

  const { data, isLoading, error } = useIdleGraphStatistics(query);

  const sortedIdleData = useMemo(() => {
    if (data?.data) {
      return sortDataByMonth(
        data?.data.map((item) => ({
          label: item?.month || "NA",
          [service]: `${item?.total_savings || 0}`,
        }))
      );
    }
    return [];
  }, [data, service]);

  const idledata = useMemo(() => {
    return sortedIdleData.map((item) => ({
      label: item.label,
      [service]: item[service],
    }));
  }, [sortedIdleData, service]);

  const handleDownloadCsv = () => {
    const csvData = [
      ["Month", "AWS", "Azure", "GCP"],
      ...idledata.map(({ label, AWS, Azure, GCP }) => [label, AWS, Azure, GCP]),
    ];

    csvLinkRef.current.link.click();
  };

  return (
    <>
      {isLoading && <BarGraphErrorStates />}
      {error && (
        <div style={{ paddingLeft: "12px" }}>
          <ScenariosErrorState
            error={error.message}
            messageConfig={{
              [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                message: "No Data Available for Selected Filters",
                additionalMessage:
                  "It looks like there are no savings data matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
              [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
                message: "No Savings Data for the Selected Date Range",
                additionalMessage:
                  "The selected date range doesn’t contain any savings data. Try choosing a different range to view available savings.",
              },
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the savings data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
            }}
          />
        </div>
      )}
      {data?.data.length > 0 && (
        <div
          className="container-fluid p-0"
          style={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            gap: "20px",
            marginLeft: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className={styles.all_cloud_text}>Idle Resources Savings</div>
            {isLoading && (
              <tr>
                <td colSpan={8}>
                  <div className="d-flex justify-content-center my-3">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                </td>
              </tr>
            )}

            <div className={styles.menu_icon_css1}>
              <Dropdown style={{ marginBottom: "2px" }}>
                <Dropdown.Toggle
                  variant="light"
                  id={styles.custom_dropdown_toggle}
                  className={styles.custom_dropdown_toggle}
                  style={{ background: "transparent", border: "none" }}
                >
                  <img src={menuicon} alt="Menu" />
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles.dropdown_menu}>
                  <Dropdown.Item
                    className={styles.dropdown_item}
                    eventKey="bar"
                    onClick={() => setChartType("bar")}
                  >
                    <img
                      src={barcharticon}
                      alt=""
                      style={{ marginRight: "10px" }}
                    ></img>
                    Bar Chart
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={styles.dropdown_item}
                    eventKey="line"
                    onClick={() => setChartType("line")}
                  >
                    <img
                      src={linecharticon}
                      alt=""
                      style={{ marginRight: "10px" }}
                    ></img>
                    Line Chart
                  </Dropdown.Item>
                  <Dropdown.Divider />

                  <Dropdown.Item
                    className={styles.dropdown_item}
                    eventKey="Download as CSV"
                    onClick={handleDownloadCsv}
                  >
                    <img
                      src={download}
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    Download as CSV
                  </Dropdown.Item>
                  <CSVLink
                    data={idledata}
                    filename={"GraphData.csv"}
                    target="_blank"
                    ref={csvLinkRef}
                    style={{ display: "none" }}
                  >
                    Download
                  </CSVLink>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 p-0">
                {chartType === "line" ? (
                  <SavingLineChartPart
                    service={service}
                    lineChartData={idledata}
                    colorMap={colorMap}
                    name={name}
                  />
                ) : (
                  <div>
                    <SavingBarGraphPart
                      service={service}
                      barData={idledata}
                      colorMap={colorMap}
                      showLegend={false}
                      name={name}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SavingAWSIdleLineChart;
