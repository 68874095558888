import React, { useMemo, useContext, useState, useEffect } from "react";
// import { RightSizingContext } from "../../right-sizing.context";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import style from "@/page-components/RI-Capacity/ri.module.css";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import { useNavigate } from "react-router-dom";
import { RiContext } from "../../ri.context";
import { useFilterOptionsRI } from "@/services/ri-recommendation/setFiltersDropdown.repo";

const FilterSection = ({ service, name, account, type }) => {
  const context = useContext(RiContext);

  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const selectedAccountIds = (context.selectedAccounts || []).map((e) => e.id);
  const res = useMemo(() => {
    return {
      service,
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
    };
  }, [service, context?.selectedAccounts]);

  const query = useMemo(() => {
    return {
      service,
      selectedTitle: context?.selectedTitle,
      tab: context?.tab,
    };
  }, [context?.selectedTitle, context?.tab, service]);

  const { data: dropdown } = useFilterOptionsRI(query);

  useEffect(() => {
    context?.setSelectedAccounts([]);
    context?.setSelectedVMFamily([]);
    context?.setSelecInstanceTypents([]);
    context?.setSelectedRegions([]);
    context?.setSelectedReservation([]);
    context?.setSelectedplateformType([]);
  }, [context.tab]);

  const filterTypeAccount =
    account === "Reservation Name" 
    ? "reservation" : "account";
  const filterTypeVMFamily = type === "VM Family" 
    ? "vmFamily" : "instance";

  const filterTypeStateAccount =
    account === "Reservation Name"
      ? context?.selectedReservation
      : context?.SelectedAccounts;
  const filterTypeStateType =
    type === "VM Family"
      ? context?.selectedVMFamily
      : context?.SelecInstanceTypents;
  const filterTypeSetStateAccount =
    account === "Reservation Name"
      ? context?.setSelectedReservation
      : context?.setSelectedAccounts;
  const filterTypeSetStateType =
    type === "VM Family"
      ? context?.setSelectedVMFamily
      : context?.setSelecInstanceTypents;

  return (
    <>
      <div
        className={style.filter_main_divs}
        style={{ minWidth: 0, width: "100%" }}
      >
        <Filter
          tittle={account}
          data={dropdown?.data}
          filterType={filterTypeAccount}
          selectedValues={filterTypeStateAccount}
          onSelect={(list) => {
            filterTypeSetStateAccount(list);
          }}
          onRemove={(list) => {
            filterTypeSetStateAccount(list);
          }}
        />
        <Filter
          tittle={type}
          data={dropdown?.data}
          filterType={filterTypeVMFamily}
          selectedValues={filterTypeStateType}
          onSelect={(list) => {
            filterTypeSetStateType(list);
          }}
          onRemove={(list) => {
            filterTypeSetStateType(list);
          }}
        />
        <Filter
          tittle={"Region"}
          data={dropdown?.data}
          filterType="region"
          selectedValues={context.selectedRegions}
          onSelect={(list) => {
            context?.setSelectedRegions(list);
          }}
          onRemove={(list) => {
            context?.setSelectedRegions(list);
          }}
        />
        <Filter
          tittle={"Platform "}
          data={dropdown?.data}
          filterType="platform"
          selectedValues={context.SelectedplateformType}
          onSelect={(list) => {
            context?.setSelectedplateformType(list);
          }}
          onRemove={(list) => {
            context?.setSelectedplateformType(list);
          }}
        />
      </div>
    </>
  );
};

const Filter = ({
  tittle,
  data,
  setSelectedValues,

  filterType,
  selectedValues,
  onSelect,
  onRemove,
}) => {
  const context = useContext(RiContext);

  const handleSelect = (selectedList) => {
    if (filterType === "reservation") {
      context?.setSelectedReservation(selectedList);
    }
    if (filterType === "vmFamily") {
      context?.setSelectedVMFamily(selectedList);
    }
    if (filterType === "instance") {
      context?.setSelecInstanceTypents(selectedList);
    }
    if (filterType === "account") {
      context.setSelectedAccounts(selectedList);
    }
    if (filterType === "platform") {
      context.setSelectedplateformType(selectedList);
    }
    if (filterType === "region") {
      context.setSelectedRegions(selectedList);
    }
  };

  const handleRemove = (selectedList) => {
    handleSelect(selectedList);
  };

  const handleTittle = (title) => {
    if (title === "Subscription") {
      title = "Account";
    }
    context?.setSelectedTitle(filterType);
  };

  const navigate = useNavigate();
  const handleTagSelection = () => {
    // Reset page to 1 when a filter is selected
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <>
      <div className={styles.filter_padding_div}>
        <div className={styles.dimension_div}>
          <div className={styles.dimension_name_css}>{tittle}</div>
          <div
            onClick={() => handleTittle(tittle)}
            className={styles.filter_group_name_css}
          >
            <FilterSearch
              multiselect={true}
              options={(data || []).map((e) => {
                return {
                  id: e.id,
                  name: e.name,
                };
              })}
              selectedValues={selectedValues || []}
              onSelect={(list) => {
                handleTagSelection();
                handleRemove(list);
              }}
              onRemove={(list) => {
                handleRemove(list);
              }}
              displayValue="name"
              placeholder="--Select--"
              toggle={
                <>
                  <FilterSearchToggle placeholder={tittle} />
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterSection;
