import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Controller, useForm } from "react-hook-form";
import info from "@/assets/img/info.svg";
import { useState } from "react";
import { useCreateBudget } from "@/services/budget/setBudgets.repo";
import { UseAllAWSAccountStatics } from "@/services/budget/getBugetsAccount.repo";
import includeIcon from "@/assets/img/addbuttoninblue.svg";
import { useStatusBudget } from "@/services/budget/setStatus.repo";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { CreateAwsBudget, SelectField } from "./helper";
import { joiResolver } from "@hookform/resolvers/joi";
import BudgetFilterComponent from "./FilterComponent";
import ToastComponent from "./toast";
import { BudgetContext } from "../budget.context";
import forecastImage from "@/assets/img/forecastImage.svg";
import reload from "@/assets/img/reloadInBudget.svg";
import greenBarGraph from "@/assets/img/greenBarGraph.svg";
import { UseForecastValue } from "@/services/budget/getForecastValue.repo";
import { formatCount } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import { Spinner } from "react-bootstrap";
import styles from "./budgets.module.css";
import closeCross from "@/assets/img/closecross.svg";

const AddNewWidgets = ({
  onClose,
  service,
  name,
  alert,
  Title,
  setRecordId,
  recordId,
  refetch,
  onOpen,
  description,
}) => {
  const context = useContext(BudgetContext);

  const query = useMemo(() => {
    return {
      scope: context?.selectedGroupAws,
      service,
    };
  }, [context?.selectedGroupAws, service]);

  const [recId, setRecId] = useState(" ");

  const handleError = (errors) => {};
  const handleAccountChange = (selected) => {
    context?.setSelectedAccounts(selected);
  };

  const { data: totaldata, isLoading } = UseAllAWSAccountStatics(query);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setError,
    setValue,
    getValues,
    watch,
    resetField,
  } = useForm({
    resolver: joiResolver(CreateAwsBudget(context)),
    defaultValues: {
      CHARGE_TYPE: "COST",
      NOTIFICATION_TYPE: "ACTUAL",
      ...(context?.selectedGroupAws === "organization" &&
      totaldata?.data?.length === 1
        ? { ACCOUNT_ID: totaldata?.data[0]?.id }
        : {}),
    },
  });
  // const StartDate = watch('START_DATE');
  // const EndDate = watch('EXPIRATION_DATE');

  // // Manipulate the values if needed
  // useEffect(() => {
  //   if (StartDate?.date) {
  //     setValue('START_DATE', StartDate.date);  // Dynamically set START_DATE from the watched value
  //   }
  // }, [StartDate]);

  // useEffect(() => {
  //   if (EndDate?.date) {
  //     setValue('EXPIRATION_DATE', EndDate.date);    // Dynamically set END_DATE from the watched value
  //   }
  // }, [EndDate]);

  let {
    mutate: Id,
    data,
    reset: createReset,
    isError,
    error: createError,
    isSuccess: successMessage,
    isPending,
  } = useCreateBudget();

  let { mutate: stausAPI, data: status } = useStatusBudget();

  const [selectedValues, setSelectedValues] = useState([]);
  const [tempSelectValues, setTempSelectValues] = useState([]);
  const [selectedTagValues, setSelectedTagValues] = useState([]);
  const [extractData, setExtractData] = useState([]);
  const [extractTagsData, setExtractTagsData] = useState([]);
  const [message, setMessage] = useState();
  const [toast, setToast] = useState(false);
  const [isForecastClicked, setIsForecastClicked] = useState(false);
  const [EndDatedisable, setEndDatedisable] = useState(true);

  const filters = useMemo(() => {
    const modifiedFormat = (selected, type, data) => {
      return Object.keys(selected)
        .filter((e) => e !== "Dimension Values" && e !== "Tag Values")
        .map((key) => ({
          name:
            data === extractData
              ? data.filter((names) => names.id === key).map((e) => e.name)[0]
              : key,
          values: selected[key].map((item) => item.id),
        }));
    };

    return {
      dimentions: modifiedFormat(selectedValues, "dimension", extractData),
      tags: modifiedFormat(selectedTagValues, "tags", extractTagsData),
    };
  }, [selectedValues, selectedTagValues, extractData, extractTagsData]);

  const clearFilterData = () => {
    setSelectedTagValues([]);
    setSelectedValues([]);
  };

  useEffect(() => {
    if (data?.data?.RECORD_ID) {
      setRecordId([data?.data?.RECORD_ID]);
    }
  }, [setRecordId, data?.data?.RECORD_ID]);

  useEffect(() => {
    if (createError && createError.message) {
      const regex = /"([^"]+)".+$/;
      const matches = createError.message.match(regex);
      if (matches && matches.length >= 2) {
        const fieldName = matches[1];
        const errorMessage = matches[0];
        setError(fieldName, { type: "server", message: errorMessage });
      } else {
        let START_DATE;
        setError(START_DATE, { type: "server", message: createError.message });
      }
    }
  }, [createError, setError]);

  useEffect(() => {
    let intervalId;
    let timeoutId;
    createReset();
    reset();
    clearFilterData();
    context?.setSelectedEmail("");
    setIsForecastClicked(false);
    if (recordId && recordId.length > 0) {
      intervalId = setInterval(() => {
        stausAPI({ body: { budgets: recordId }, service: service });
      }, 10000);
      createReset();
      reset();
      clearFilterData();

      context?.setSelectedEmail("");
      setEndDatedisable(true);
      setIsForecastClicked(false);
      timeoutId = setTimeout(() => refetch(), 10000);
      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    }
  }, [service, recordId, reset, createReset]);

  useEffect(() => {
    let timeoutId;
    if (status && status?.status && status?.status.length > 0) {
      const statusData = status?.status[0].status?.budget_status?.toLowerCase();

      if (statusData === "completed" || statusData === "failed") {
        setRecordId([]);
        setRecId(" ");
        onClose(false);
        createReset();
        reset();
        clearFilterData();
      }
    }
  }, [setRecordId, status, createReset, reset, onClose]);

  const handleAddEmail = () => {
    const input = document.getElementById("emailInput");
    const value = input.value.trim();

    if (value) {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

      if (!isValidEmail) {
        setError("ALERTS_RECEIPIENTS", {
          type: "manual",
          message: "Please enter a valid email address",
        });
      } else if (context?.SelectedEmail?.includes(value)) {
        setError("ALERTS_RECEIPIENTS", {
          type: "manual",
          message:
            "This email address is already added. Please enter a different email.",
        });
      } else {
        setError("ALERTS_RECEIPIENTS", null);
        context?.setSelectedEmail((prevRecipients) => {
          const updatedRecipients = [...prevRecipients, value];
          setValue("ALERTS_RECEIPIENTS", updatedRecipients, {
            shouldValidate: true,
          });
          return updatedRecipients;
        });
        input.value = "";
      }
    }
  };

  const handleRemoveEmail = (index) => {
    context?.setSelectedEmail((prevRecipients) =>
      prevRecipients.filter((_, i) => i !== index)
    );
  };
  const onSubmit = async (data2) => {
    // data2.START_DATE = data2.START_DATE?.date
    // data2.EXPIRATION_DATE =data2?.EXPIRATION_DATE?.date
    data2.START_DATE = format(data2.START_DATE, "yyyy-MM-dd");
    data2.EXPIRATION_DATE = format(data2?.EXPIRATION_DATE, "yyyy-MM-dd");
    delete data2.Dimension;
    delete data2.Tags;

    Id({
      body: {
        ...data2,
        CHARGE_TYPE: "COST",
        NOTIFICATION_TYPE: "ACTUAL",
        filters,
        ...(context?.selectedGroupAws === "organization" &&
        totaldata?.data?.length === 1
          ? { ACCOUNT_ID: totaldata?.data[0]?.id }
          : {}),
      },
      service: service,
    });

    // if (!isError || !createError) {
    //   onClose(false);
    // }

    // setTimeout(() => {
    //   refetch();
    // }, 1000);
    // onClose(false);
  };

  const handleCloseOverlay = () => {
    onClose(false);
    createReset();
    reset();
    clearFilterData();
    setEndDatedisable(true);
    context?.setSelectedGroupAws("organization");
    context?.setSelectedEmail("");
    setIsForecastClicked(false);
  };

  useEffect(() => {
    reset({
      scope: context?.selectedGroupAws,
      CHARGE_TYPE: "COST",
      THRESHOLD_TYPE: "",
      NAME: "",
      AMOUNT: "",
      THRESHOLD: "",
      ALERTS_RECEIPIENTS: "",
    });
    context?.setSelectedAccounts("");
    reset();
    clearFilterData();
    setEndDatedisable(true);
  }, [context?.selectedGroupAws]);

  useEffect(() => {
    if (totaldata?.data?.length === 1) {
      context?.setSelectedAccounts(totaldata?.data[0]?.id);
    }
  }, [context.selectedGroupAws, totaldata?.data]);

  const selectedPeriod = watch("PERIOD");

  // console.log("selectedPeriod",selectedPeriod)

  //   useEffect(() => {
  //     if (selectedPeriod) {
  //         setValue("START_DATE", "");
  //         setValue("EXPIRATION_DATE", "");
  //     }
  // }, [selectedPeriod]);

  //   useEffect(() => {
  //     const startDate = watch("START_DATE");
  //     const expirationDate = watch("EXPIRATION_DATE");

  //     if (!startDate || !expirationDate) {
  //         setValue("EXPIRATION_DATE", "");
  //         setValue("START_DATE", "");
  //     }
  // }, [selectedPeriod, setValue, watch]);

  // ----------------------------------------------------------------------------------

  const startDate = watch("START_DATE");
  const expirationDate = watch("EXPIRATION_DATE");

  const isFormCompleted =
    selectedPeriod && startDate && expirationDate && context?.SelectedAccounts;

  useEffect(() => {
    setIsForecastClicked(false);
  }, [selectedPeriod, startDate, expirationDate, context?.SelectedAccounts]);

  const periodDisplayMapping = {
    MONTHLY: "Month",
    QUARTERLY: "Quarter",
    ANNUALLY: "Annual",
  };

  const forecastQuery = useMemo(() => {
    return {
      service,
      period: selectedPeriod,
      start_date: startDate,
      expiration_date: expirationDate,
      account_id: context?.SelectedAccounts,
      filters,
    };
  }, [
    context?.SelectedAccounts,
    expirationDate,
    filters,
    selectedPeriod,
    service,
    startDate,
  ]);

  const {
    data: forecastData,
    mutate: refetchForecastData,

    // refetch: refetchForecastData,
    isSuccess: forecastSuccess,
    isPending: forecastLoading,
    error: forecastError,
  } = UseForecastValue(isForecastClicked ? forecastQuery : null);

  useEffect(() => {
    if (isForecastClicked) {
      refetchForecastData();
    }
  }, [isForecastClicked, refetchForecastData]);

  const handleOpenForecast = () => {
    setIsForecastClicked(true);
    refetchForecastData();
  };

  const handleReload = () => {
    refetchForecastData();
  };

  const formattedForecastCost = formatCount(
    Number(forecastData?.data?.total_forecast?.Amount || 0)
  );

  useEffect(() => {
    if (successMessage && data !== undefined) {
      setEndDatedisable(true);
      setToast(true);
      setMessage({ message: data?.message, type: "success" });
      setTimeout(() => onClose(false), 3000);
    } else if (isError) {
      setToast(true);
      setMessage({ message: createError?.message, type: "Anamaly" });
    }
  }, [successMessage, data, isError, createError, onClose, setMessage]);

  useEffect(() => {
    if (isForecastClicked && forecastError && !forecastSuccess) {
      setToast(true);
      setMessage({ message: "Unable to get forecast info.", type: "Anamaly" });
      setTimeout(() => setToast(false), 3000);
    }
    if (isForecastClicked && forecastSuccess) {
      setToast(false);
    }
  }, [isForecastClicked, forecastError, forecastSuccess, setMessage]);


  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const currentMonth = useMemo(() => new Date().toLocaleString('en-US', { month: 'short' }), []);
  const currentQuarter = useMemo(() => {
    const month = new Date().getMonth() + 1; 
    if (month >= 1 && month <= 3) return 1; 
    if (month >= 4 && month <= 6) return 2;
    if (month >= 7 && month <= 9) return 3;
    return 4;
  }, []);

  const [periodValue, setPeriodValue] = useState();
  const [periodValueEnd, setPeriodValueEnd] = useState();
  const [yearValue, setYearValue] = useState(currentYear);
  const [yearValueEnd, setYearValueEnd] = useState(currentYear);
  const formattedQuarter = `Q${currentQuarter}`;
  useEffect(() => {
    if (selectedPeriod === "MONTHLY") {
      setPeriodValue(yearValue > currentYear ? "Jan" : currentMonth);
    }
  
    if (selectedPeriod === "QUARTERLY") {
      setPeriodValue(yearValue > currentYear ? "Q1" : formattedQuarter);
    }
  }, [yearValue]);
  
  useEffect(() => {
    if (selectedPeriod === "MONTHLY") {
      setPeriodValueEnd(yearValueEnd > currentYear ? "Jan" : currentMonth);
    }
  
    if (selectedPeriod === "QUARTERLY") {
      setPeriodValueEnd(yearValueEnd > currentYear ? "Q1" : formattedQuarter);
    }
  }, [yearValueEnd]);
  
  

  useEffect(() => {
    const periodDefaults = {
      QUARTERLY: formattedQuarter,
      MONTHLY: currentMonth,
      ANNUALLY: "Jan",
    };

    const defaultPeriodValue = periodDefaults[selectedPeriod] || currentMonth;
    setPeriodValue((prev) =>
      prev !== defaultPeriodValue ? defaultPeriodValue : prev
    );
    setPeriodValueEnd((prev) =>
      prev !== defaultPeriodValue ? defaultPeriodValue : prev
    );
    setYearValue((prev) => (prev !== currentYear ? currentYear : prev));
    setYearValueEnd((prev) => (prev !== currentYear ? currentYear : prev));
  }, [currentYear, selectedPeriod]);

  const calculateDate = useCallback(
    (selectedYear, selectedPeriodValue) => {
      if (!selectedYear || !selectedPeriodValue) return "";
      try {
        let date;
        const currentDate = new Date();
        const currentMonthIndex = currentDate.getMonth();
        if (selectedPeriod === "QUARTERLY") {
          const selectedQuarter =
            parseInt(selectedPeriodValue.replace("Q", ""), 10) || 1;
          const startMonthOfQuarter = (selectedQuarter - 1) * 3;
          date = new Date(selectedYear, startMonthOfQuarter, 1);
        } else if (selectedPeriod === "MONTHLY") {
          const selectedMonth = new Date(
            Date.parse(selectedPeriodValue + " 1")
          ).getMonth();
          date = new Date(selectedYear, selectedMonth, 1);
        } else {
          date = new Date(selectedYear, 0, 1);
        }
        return date.toLocaleDateString("en-CA");
      } catch (error) {
        return "";
      }
    },
    [selectedPeriod]
  );

  const calculateLastDate = useCallback(
    (selectedYear, selectedPeriodValue) => {
      if (!selectedYear || !selectedPeriodValue) return "";
      try {
        let date;
        const currentDate = new Date();
        const currentMonthIndex = currentDate.getMonth();
        if (selectedPeriod === "QUARTERLY") {
          const selectedQuarter =
            parseInt(selectedPeriodValue.replace("Q", ""), 10) || 1;
          const endMonthOfQuarter = selectedQuarter * 3 - 1;
          date = new Date(selectedYear, endMonthOfQuarter + 1, 0);
        } else if (selectedPeriod === "MONTHLY") {
          const selectedMonth = new Date(
            Date.parse(selectedPeriodValue + " 1")
          ).getMonth();
          date = new Date(selectedYear, selectedMonth + 1, 0);
        } else {
          date = new Date(selectedYear, 11, 31);
        }
        return date.toLocaleDateString("en-CA");
      } catch (error) {
        return "";
      }
    },
    [selectedPeriod]
  );

  useEffect(() => {
    if (!startDate) {
      const initialDate = calculateDate(yearValue, periodValue);
      setValue("START_DATE", initialDate);
    } else {
      const newDate = calculateDate(yearValue, periodValue);
      setValue("START_DATE", newDate);
    }
  }, [calculateDate, periodValue, setValue, startDate, yearValue]);

  useEffect(() => {
    if (!expirationDate) {
      const defaultDate = calculateLastDate(yearValueEnd, periodValueEnd);
      setValue("EXPIRATION_DATE", defaultDate);
    } else {
      const newDate = calculateLastDate(yearValueEnd, periodValueEnd);
      setValue("EXPIRATION_DATE", newDate);
    }
  }, [
    calculateLastDate,
    currentYear,
    expirationDate,
    periodValueEnd,
    setValue,
    yearValueEnd,
  ]);
  const isPastQuarterStart = (quarter) => {
    const quarterNumber = parseInt(quarter.replace("Q", ""), 10);
    return quarterNumber < currentQuarter && yearValue === currentYear;
  };
  const isPastQuarterend = (quarter) => {
    const quarterNumber = parseInt(quarter.replace("Q", ""), 10);
    return quarterNumber < currentQuarter &&  yearValueEnd === currentYear;
  };
  
  const handleStartPeriodChange = (selectedPeriodValue) => {
    setPeriodValue(selectedPeriodValue);
  };

  const handleStartYearChange = (selectedYear) => {
    setYearValue(selectedYear);
  };

  const handleEndPeriodChange = (selectedPeriodValue) => {
    setPeriodValueEnd(selectedPeriodValue);
  };

  const handleEndYearChange = (selectedYear) => {
    setYearValueEnd(selectedYear);
  };
  return (
    <>
      {toast && (
        <ToastComponent message={message} toast={toast} setToast={setToast} />
      )}
      <Offcanvas
        style={{ width: "50%" }}
        show={onOpen}
        placement="end"
        onHide={handleCloseOverlay}
        backdrop="static"
      >
        <form
          className="mybudgetform"
          onSubmit={handleSubmit(onSubmit, handleError)}
        >
          <div className="budget_main_div">
            {/* -----------------create budget name------------------------------------------------------------ */}
            <div className="budget_first_box">
              <Offcanvas.Header id="createBudgetHeader" closeButton>
                <div className="create_budget">
                  <Offcanvas.Title>
                    <div className="create_budget_name">{Title}</div>
                  </Offcanvas.Title>
                </div>
              </Offcanvas.Header>
              <p className="create_budget_disc">
                Establish a financial plan and configure notifications to assist
                you in tracking your expenditures.
              </p>
            </div>
            <Offcanvas.Body id="createBudgetBody">
              <div className="budget_account_box">
                <div className="budget_account">
                  <div className="budget_name">Budget Account</div>
                  <div className="budget_discription">{description}</div>
                </div>
                <span className="radioButtonContainer">
                  <RadioButton
                    name="scope"
                    value="organization"
                    title="Organization"
                    control={control}
                  />
                  <RadioButton
                    name="scope"
                    value="account"
                    title="Account"
                    control={control}
                  />
                </span>
                <SelectField
                  control={control}
                  title={name}
                  placeholder={name}
                  rules={errors.ACCOUNT_ID}
                  fieldName={"ACCOUNT_ID"}
                  service={service}
                  commonFieldData={(totaldata?.data || []).map((item) => item)}
                  isLoading={isLoading}
                  onSelect={handleAccountChange}
                  disableOptionAWs={true}
                />
              </div>
              {context?.SelectedAccounts !== "" && (
                <BudgetFilterComponent
                  service={service}
                  rules={errors}
                  control={control}
                  setValue={setValue}
                  selectedTagValues={selectedTagValues}
                  selectedValues={selectedValues}
                  setSelectedTagValues={setSelectedTagValues}
                  setSelectedValues={setSelectedValues}
                  tempSelectValues={tempSelectValues}
                  setTempSelectValues={setTempSelectValues}
                  extractTagsData={extractTagsData}
                  setExtractTagsData={setSelectedTagValues}
                  setExtractData={setExtractData}
                  resetField={resetField}
                  clearFilterData={clearFilterData}
                />
              )}
              {/*- ------------------------------------------Name, period,start date and end date---------------------------------------------------------- */}
              <div className="budget_account_box">
                <div className="budget_account">
                  <div className="budget_name">Budget Details</div>
                  <div className="budget_discription">
                    Give your budget a unique name. Select the time window it
                    analyses during each evaluation period, its expiration date
                    and the amount.
                  </div>
                </div>
                <div className="charge_select">
                  <div className="accountid_box">
                    <div className="accountid_name">
                      Name
                      <span className="astriek_css">
                        <b> *</b>
                      </span>
                    </div>
                    <div>
                      <input
                        className="input_box_css"
                        name="NAME"
                        placeholder="Enter a unique name"
                        {...register("NAME")}
                      />
                    </div>
                      <div style={{ height: "10px", display: "flex" }}>
                        {errors.NAME && (
                          <span className="budgetsError">
                            {errors.NAME.message}
                          </span>
                        )}
                      </div>
                  </div>
                  <span className="semicolon_css"></span>

                  <SelectField
                    control={control}
                    rules={errors.PERIOD}
                    title={"Period"}
                    placeholder={"Select"}
                    fieldName="PERIOD"
                    service={service}
                    commonFieldData={[
                      { id: "MONTHLY", name: "Monthly" },
                      { id: "QUARTERLY", name: "Quarterly" },
                      { id: "ANNUALLY", name: "Annually" },
                    ]}
                  />
                </div>
                {selectedPeriod && (
                  <div className="charge_select" style={{ width: "99%" }}>
                    <div className="accountid_box" style={{ width: "50%" }}>
                      <div className="accountid_name">
                        Start{" "}
                        {selectedPeriod === "MONTHLY"
                          ? "Month"
                          : selectedPeriod === "ANNUALLY"
                          ? "Year"
                          : selectedPeriod === "QUARTERLY"
                          ? "Quarter"
                          : ""}
                        <span className="astriek_css">
                          <b>*</b>
                        </span>
                      </div>
                      <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", gap: "10px" }}>
                          {selectedPeriod !== "ANNUALLY" && (
                            <select
                              className={styles.input_box_css}
                              value={periodValue}
                              onChange={(e) =>
                                handleStartPeriodChange(e.target.value)
                              }
                            >
                              {selectedPeriod === "QUARTERLY"
                                ? ["Q1", "Q2", "Q3", "Q4"].map((quarter) => (
                                    <option key={quarter} value={quarter} disabled={isPastQuarterStart(quarter)}>
                                      {quarter}
                                    </option>
                                  ))
                                : [
                                    "Jan",
                                    "Feb",
                                    "Mar",
                                    "Apr",
                                    "May",
                                    "Jun",
                                    "Jul",
                                    "Aug",
                                    "Sep",
                                    "Oct",
                                    "Nov",
                                    "Dec",
                                  ].map((month,index) => (
                                    <option key={month} value={month}  disabled={(index < new Date().getMonth()) && (yearValue===currentYear) }>
                                      {month}
                                    </option>
                                  ))}
                            </select>
                          )}
                          <select
                            className={styles.input_box_css}
                            value={yearValue}
                            onChange={(e) =>
                              handleStartYearChange(
                                parseInt(e.target.value, 10)
                              )
                            }
                          >
                            {Array.from(
                              { length: 10 },
                              (_, i) => currentYear + i
                            ).map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div style={{ height: "10px", display: "flex" }}>
                        {errors.START_DATE && (
                          <p className="budgetsError">
                            {errors.START_DATE.message}
                          </p>
                        )}
                          </div>
                      </div>
                    </div>
                    <span className="semicolon_css"></span>
                    {/* End Date Section */}
                    <div className="accountid_box" style={{ width: "50%" }}>
                      <div className="accountid_name">
                        End{" "}
                        {selectedPeriod === "MONTHLY"
                          ? "Month"
                          : selectedPeriod === "ANNUALLY"
                          ? "Year"
                          : selectedPeriod === "QUARTERLY"
                          ? "Quarter"
                          : ""}
                        <span className="astriek_css">
                          <b>*</b>
                        </span>
                      </div>
                      <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", gap: "10px" }}>
                          {selectedPeriod !== "ANNUALLY" && (
                            <select
                              className={styles.input_box_css}
                              value={periodValueEnd}
                              onChange={(e) =>
                                handleEndPeriodChange(e.target.value)
                              }
                            >
                              {selectedPeriod === "QUARTERLY"
                                ? ["Q1", "Q2", "Q3", "Q4"].map((quarter) => (
                                    <option key={quarter} value={quarter} disabled={isPastQuarterend(quarter)}>
                                      {quarter}
                                    </option>
                                  ))
                                : [
                                    "Jan",
                                    "Feb",
                                    "Mar",
                                    "Apr",
                                    "May",
                                    "Jun",
                                    "Jul",
                                    "Aug",
                                    "Sep",
                                    "Oct",
                                    "Nov",
                                    "Dec",
                                  ].map((month,index) => (
                                    <option key={month} value={month} disabled={index < new Date().getMonth() && yearValueEnd===currentYear}>
                                      {month}
                                    </option>
                                  ))}
                            </select>
                          )}
                          <select
                            className={styles.input_box_css}
                            value={yearValueEnd}
                            onChange={(e) =>
                              handleEndYearChange(parseInt(e.target.value, 10))
                            }
                          >
                            {Array.from(
                              { length: 10 },
                              (_, i) => currentYear + i
                            ).map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div style={{ height: "10px", display: "flex" }}>
                        {errors.EXPIRATION_DATE && (
                          <p className="budgetsError">
                            {errors.EXPIRATION_DATE.message}
                          </p>
                        )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* ---------------------------------------amount section------------------------------------------------------------- */}
              <div className="budget_account_box">
                <div className="budget_account">
                  <div className="budget_name">Budget Amount</div>
                  <div className="budget_discription">
                    Give your budget amount threshold
                  </div>
                </div>
                <div
                  className="charge_select"
                  style={{ width: "100%", alignItems: "flex-end" }}
                >
                  <div className="accountid_box">
                    <div className="accountid_name">
                      Amount ($){" "}
                      <span className="astriek_css">
                        <b>*</b>
                      </span>{" "}
                    </div>
                    <div>
                      <input
                      type="number"
                        className="input_box_css"
                        name="AMOUNT"
                        placeholder="Enter amount"
                        {...register("AMOUNT")}
                      />
                    </div>
                    <div style={{ height: "10px", display: "flex" }}>
                      {errors.AMOUNT && (
                        <p className="budgetsError">{errors.AMOUNT.message}</p>
                      )}
                    </div>
                  </div>
                  <span className="semicolon_css"></span>
                  {isFormCompleted && (
                    <>
                      {!isForecastClicked ? (
                        <div
                          className="fore_casted"
                          style={{
                            marginBottom: errors.AMOUNT ? "11px" : "11px",
                            cursor: "pointer",
                          }}
                          onClick={handleOpenForecast}
                        >
                          <img src={forecastImage} alt=""></img>
                          <span
                            className="forecast_name"
                            style={{ color: "#fff" }}
                          >
                            Check Forecasted Cost
                          </span>
                        </div>
                      ) : (
                        <div
                          className="forecast_data"
                          style={{
                            width: "100%",
                            marginBottom: errors.AMOUNT ? "19px" : "0px",
                          }}
                        >
                          <div
                            className=""
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div className="for_cast">
                              <img src={greenBarGraph} alt=""></img>
                              <span className="forcasted_cost">
                                Forecasted Cost
                              </span>
                            </div>

                            {forecastLoading && (
                              <Spinner
                                style={{
                                  width: "17px",
                                  height: "17px",
                                  color: "grey",
                                }}
                              />
                            )}
                            {!forecastLoading && (
                              <img
                                style={{ cursor: "pointer" }}
                                src={reload}
                                alt=""
                                onClick={handleReload}
                              ></img>
                            )}
                          </div>
                          <div>
                            <span className="forecast_value">
                              {" "}
                              {formattedForecastCost}
                            </span>
                            <span className="period">
                              /{periodDisplayMapping[selectedPeriod] || ""}
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              {/* -------------------------------------threshold section-------------------------------------------------- */}
              <div className="budget_account_box">
                <div className="budget_account">
                  <div className="budget_name">
                    {alert}
                    <span className="astriek_css">
                      <b>*</b>
                    </span>
                  </div>
                  <div className="budget_discription">
                    Configure alert conditions and send email notifications
                    based on your spend.
                  </div>
                </div>
                <div className="charge_select">
                  <SelectField
                    control={control}
                    title={"Notification Type"}
                    rules={errors.NOTIFICATION_TYPE}
                    service={service}
                    placeholder={"Select type"}
                    fieldName="NOTIFICATION_TYPE"
                    commonFieldData={[{ id: "ACTUAL", name: "Actual" }]}
                    disableOptionAWs={true}
                  />
                  <span className="semicolon_css"></span>
                  <SelectField
                    control={control}
                    title={"Comparison Operator"}
                    rules={errors.COMPARISON_OPERATOR}
                    placeholder={"Select"}
                    fieldName="COMPARISON_OPERATOR"
                    service={service}
                    commonFieldData={[
                      { id: "GREATER_THAN", name: "Greater Than" },
                      { id: "LESS_THAN", name: "Less Than" },
                      { id: "EQUAL_TO", name: "Equal To" },
                    ]}
                  />
                </div>

                <div className="charge_select">
                  <SelectField
                    control={control}
                    title={"Threshold Type"}
                    placeholder={"Select type"}
                    fieldName="THRESHOLD_TYPE"
                    rules={errors.THRESHOLD_TYPE}
                    service={service}
                    commonFieldData={[
                      { id: "PERCENTAGE", name: "Percentage" },
                      { id: "ABSOLUTE_VALUE", name: "Absolute Value" },
                    ]}
                  />

                  <span className="semicolon_css"> </span>
                  <div className="accountid_box">
                    <div className="accountid_name">
                      Threshold
                      <span className="astriek_css">
                        <b> *</b>
                      </span>
                    </div>
                    <div>
                      <input
                        type="number"
                        className="input_box_css"
                        name="THRESHOLD"
                        placeholder="Enter"
                        {...register("THRESHOLD")}
                      />
                    </div>
                    <div style={{ height: "10px", display: "flex" }}>
                      {errors.THRESHOLD && (
                        <p className="budgetsError">
                          {errors.THRESHOLD.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="budget_account_box">
                <div className="budget_account">
                  <div className="budget_name">
                    Alert recipients (email)
                    <span className="astriek_css">
                      <b> *</b>
                    </span>
                  </div>
                </div>
                <div className="accountid_box" style={{gap:"7px"}}>
                  {/* <div className="accountid_name"></div> */}
                  <div style={{ display: "flex" }}>
                    <div>
                      <div>
                        <input
                          id="emailInput"
                          type="email"
                          className="input_box_css"
                          name="ALERTS_RECEIPIENTS"
                          placeholder="example@email.com"
                        />
                      </div>
                      <div style={{ height: "10px", display: "flex" }}>
                        {errors.ALERTS_RECEIPIENTS && (
                          <p className="budgetsError">
                            {errors.ALERTS_RECEIPIENTS.message ||
                              (Array.isArray(errors.ALERTS_RECEIPIENTS) &&
                                errors.ALERTS_RECEIPIENTS[0]?.message)}
                          </p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        marginLeft: "16px",
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={handleAddEmail} // Updated function for onClick
                    >
                      <img style={{ width: "12px" }} src={includeIcon} alt="" />
                      <span
                        style={{
                          color: "#056EE5",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        Add Email
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    {(context?.SelectedEmail || []).map((recipient, index) => (
                      <div key={index} className={styles.badgeContainers}>
                        <span key={index} className={styles.badgesValues}>
                          <span className={styles.badgeSelectedValue}>
                            {recipient}
                          </span>
                          <span>
                            <img
                              src={closeCross}
                              alt="crossIcon"
                              onClick={() => handleRemoveEmail(index)}
                            />
                          </span>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* ----------------------------------learn more section------------------------------------------ */}
              <div className="learn_more_box">
                <div>
                  <img src={info} alt="" />
                </div>
                <div>
                  <span className="learn_more_css">
                    Your budget evaluation will begin in a few hours.
                  </span>
                  <span className="highlighted_name">Learn More</span>
                </div>
              </div>

              <div className="button_box">
                <div className="cancel_create_button">
                  <button
                    type="button"
                    style={{ cursor: "pointer" }}
                    className="cancel_button"
                    onClick={() => {
                      handleCloseOverlay();
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="create_button">
                    {isPending ? "Creating..." : "Create"}
                  </button>
                </div>
              </div>
            </Offcanvas.Body>
          </div>
        </form>
      </Offcanvas>
    </>
  );
};

export const RadioButton = ({ control, name, title, value }) => {
  const context = useContext(BudgetContext);

  return (
    <>
      <div className="radioButtonSection">
        <Controller
          name={name}
          control={control}
          defaultValue={context?.selectedGroupAws}
          render={({ field }) => (
            <>
              <input
                type="radio"
                name={name}
                id={value}
                value={value}
                checked={field?.value === value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  context?.setSelectedGroupAws(e.target.value);
                }}
              />
              <label>{title}</label>
            </>
          )}
        />
      </div>
    </>
  );
};

export default AddNewWidgets;
