import React, { useState, useMemo } from "react";
import SavingHeaderContainer from "../components/saving-header-container.part";
import SavingAWSIdleLineChart from "../components/saving-aws-idle-line-chart.part";
import SavingIdleTableComponent from "../components/saving-idle-table-component.part";
import SavingAWSRIGHTLineChart from "../components/rightSizing-aws-chart.part";
import SnapshotAWSChart from "../components/snapshots-chart.part";
import subDays from "date-fns/subDays";
import { format, startOfMonth, endOfMonth } from "date-fns";
import TablePartSaving from "../components/table-part-saving.part";
import { useStatisticsHeaderAzure } from "@/services/saving/getAzureHeaderV2.repo";
import SnapshotTablePartAWSSaving from "../components/snapshot-table-part-aws-saving.part";
import FilterSection from "../components/filter-section/filter-section.part";
import {
  SavingProvider,
  SavingContext,
} from "@/page-components/savings/components/saving-context-file.part";
import { useContext } from "react";

const AWSCloudSaving = () => {
  return (
    <SavingProvider>
      <AWSSavingPage />
    </SavingProvider>
  );
};

const AWSSavingPage = ({ selectionState, selection, service }) => {
  const [selectedTab, setSelectedTab] = useState("Idle Resources");
  const [range, setRange] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    },
  ]);
  let fromDate = format(range[0].startDate, "yyyy-MM-dd");
  let toDate = format(range[0].endDate, "yyyy-MM-dd");

  const context = useContext(SavingContext);
  const query = useMemo(() => {
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service: "aws",
      toDate,
      fromDate,
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        tags: tags.length ? tags : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectRegions,
    context?.selectedTags,
    fromDate,
    toDate,
  ]);

  const { data, isLoading, error } = useStatisticsHeaderAzure(query);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const tabConfigurations = [
    {
      tabName: "Idle Resources",
      components: [
        <SavingAWSIdleLineChart
          service="aws"
          toDate={toDate}
          fromDate={fromDate}
          name="Idle Resources"
        />,
        <SavingIdleTableComponent
          service="aws"
          toDate={toDate}
          fromDate={fromDate}
          name="Account Name"
        />,
      ],
    },
    {
      tabName: "Rightsizing",
      components: [
        <SavingAWSRIGHTLineChart
          service="aws"
          toDate={toDate}
          fromDate={fromDate}
          name="Rightsizing"
        />,
        <TablePartSaving
          service="aws"
          toDate={toDate}
          fromDate={fromDate}
          name="Account Name"
        />,
      ],
    },

    {
      tabName: "Snapshots",
      components: [
        <SnapshotAWSChart
          toDate={toDate}
          fromDate={fromDate}
          name="Snapshots"
          service="aws"
        />,
        <SnapshotTablePartAWSSaving
          service="aws"
          toDate={toDate}
          fromDate={fromDate}
          name="Account Name"
        />,
      ],
    },
  ];

  const selectedTabConfig = tabConfigurations.find(
    (config) => config.tabName === selectedTab
  );

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 p-0">
            <SavingHeaderContainer
              service="aws"
              ShowTabs={true}
              selectedTab={selectedTab}
              onTabChange={handleTabChange}
              toDate={toDate}
              fromDate={fromDate}
              range={range}
              setRange={setRange}
              data={data}
              error={error}
              isLoading={isLoading}
            />

            {selectedTabConfig && (
              <div className="row w-100">
                <div
                  className="container-fluid col-lg-10"
                  style={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                    gap: "24px",
                    paddingLeft: "52px",
                    paddingRight: "0px",
                    margin: 0,
                    borderRight: "1px solid lightgray",
                  }}
                >
                  <div className="row w-100">
                    <div className="col-12 p-0 pe-2 pt-4 d-flex gap-4 flex-column">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          paddingRight: "12px",
                        }}
                      >
                        {selectedTabConfig.components.map(
                          (component, index) => (
                            <React.Fragment key={index}>
                              {component}
                            </React.Fragment>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 p-0" style={{ height: "100%" }}>
                  <FilterSection service="aws" name="Account Name" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AWSCloudSaving;
