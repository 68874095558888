import React, { useContext, useState } from "react";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import clsx from "clsx";
import { CostRecommendationContext } from "../costContext";
import { Navigate } from "react-router-dom";
import downarrow from "@/assets/img/downarrow.svg";
import vectorUP from "@/assets/img/VectoruP.svg";
import style from "@/page-components/cost-Recommendation/cost-Recommendation.module.css";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import HeaderState from "@/components/States/HeaderState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";

const ActiveCostHeader = ({
  savingFor,
  heading,
  tilldate,
  active,
  data,
  service,
  error,
  isLoading,
}) => {
  const context = useContext(CostRecommendationContext);

  const [selectedOption, setSelectedOption] = useState(context?.viewType);
  const handleDropdownChange = (eventKey) => {
    context?.setViewType(eventKey);
    setSelectedOption(eventKey);
  };
  const handleTagSelection = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    Navigate(`?${searchParams.toString()}`);
  };
  function formatCount(count) {
    const million = 1000000;
    const thousand = 1000;

    if (Math.abs(count) >= million) {
      return (count / million).toFixed(1) + "M";
    } else if (Math.abs(count) >= thousand) {
      return (count / thousand).toFixed(1) + "k";
    } else {
      return count.toString();
    }
  }

  const current_costs = Number(data?.current_cost || "--").toFixed(2);
  const current_costs_pm = formatCount(Math.abs(current_costs));

  const projected_cost = Number(data?.projected_cost || "--").toFixed(2);
  const projected_cost_pm = formatCount(Math.abs(projected_cost));

  const projected_savings = Number(data?.projected_savings || "--").toFixed(2);
  const projected_savings_pm = formatCount(Math.abs(projected_savings));

  return (
    <>
      <div
        className="container-fluid main-container"
        style={{ padding: "16px 50px 20px 40px" }}
      >
        <div className=" Inner_main_container">
          <div className={clsx(styles.rec_inner_first_container)}>
            <div className="inner_first_container_text">Recommendations</div>
          </div>

          {error && (
            <ScenariosErrorState
              headerItem="header"
              error={error.message}
              messageConfig={{
                [API_NO_DATA_FOUND_FOR_FILTERS_ERROR_CUSTOM_WIDGET]: {
                  message: "No Data Available for Selected Filters",
                  additionalMessage:
                    "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
                },
                [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                  message: "No Data Available",
                  additionalMessage:
                    "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                },
                [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
              }}
            />
          )}
          {data && (
            <div className={clsx("row", styles.rec_inner_second_container)}>
              <div
                className={clsx(
                  "container col-md-3 col-sm-12 rounded",
                  styles.rec_details_first
                )}
                style={{ width: "25%" }}
              >
                <div
                  className={clsx(
                    styles.rec_second_div_first_container_firstDiv
                  )}
                >
                  <div className={clsx("container", styles.upperContent_texts)}>
                    {heading}
                  </div>
                  <div className="container ideal_resource_count_number">
                    <div
                      style={{
                        fontSize: service === "all-clouds" ? "32px" : "44px",
                      }}
                      className={clsx("container", styles.rec_number_container)}
                    >
                      {data?.cost_recommendations_total || "--"}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "40%", padding: "0px", display: "flex" }}>
                <div
                  style={{ width: "40%" }}
                  className={clsx(styles.rec_details_third)}
                >
                  <div className={clsx("container", styles.upperContent_texts)}>
                    Current Costs
                  </div>
                  <div
                    className={clsx(
                      "container ",
                      styles.rec_ideal_resource_count_number
                    )}
                  >
                    <div
                      className="container "
                      style={{ display: "flex", padding: "0px", gap: "4px" }}
                    >
                      <div className={clsx(styles.currunt_cost)}>
                        {current_costs >= 0 ? (
                          <ToolTipComponent data={data?.current_cost || 0}>
                            <span style={{ cursor: "context-menu" }}>
                              ${current_costs_pm}
                            </span>
                          </ToolTipComponent>
                        ) : (
                          <ToolTipComponent data={data?.current_cost || 0}>
                            <span style={{ cursor: "context-menu" }}>
                              -${current_costs_pm}
                            </span>
                          </ToolTipComponent>
                        )}
                      </div>
                      <div className={clsx("container", styles.currunt_cost1)}>
                        /m
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ width: "60%" }}
                  className={clsx(styles.rec_details_third)}
                >
                  <div className={clsx("container", styles.upperContent_texts)}>
                    PROJECTED Costs
                  </div>
                  <div
                    className={clsx(
                      "container ",
                      styles.rec_ideal_resource_count_number
                    )}
                  >
                    <div
                      className="container "
                      style={{ display: "flex", padding: "0px", gap: "4px" }}
                    >
                      <div className={clsx(styles.currunt_cost)}>
                        {projected_cost >= 0 ? (
                          <ToolTipComponent data={data?.projected_cost || 0}>
                            <span style={{ cursor: "context-menu" }}>
                              ${projected_cost_pm}
                            </span>
                          </ToolTipComponent>
                        ) : (
                          <ToolTipComponent data={data?.projected_cost || 0}>
                            <span style={{ cursor: "context-menu" }}>
                              -${projected_cost_pm}
                            </span>
                          </ToolTipComponent>
                        )}
                      </div>
                      <div
                        className={clsx("container", styles.currunt_cost1)}
                        style={{ gap: "8px" }}
                      >
                        /m
                        <span className={style.projectedNotify}>
                          Down by {Number(data?.percentage_change).toFixed(2)}%
                        </span>
                      </div>
                    </div>
                  </div>
                  <span
                    style={{
                      fontSize: "13px",
                      fontWeight: "400",
                      color: "#495057",
                    }}
                  >
                    (Including all recommendations)
                  </span>
                </div>
              </div>

              <div
                className={clsx(
                  active === "active" && styles.greenColor,
                  active === "archive" && styles.redColor,
                  styles.rec_second_div_third_container
                )}
                style={{ width: "35%" }}
              >
                <div className="container-fluid innerContainer-1">
                  <div className="container innerContainer-div">
                    <div className="container innerContainer-text">
                      <div className="container innerContainer-text-1">
                        {savingFor}
                      </div>
                      <div
                        className="container innerContainer-text-2"
                        style={{ display: "flex", gap: "4px" }}
                      >
                        {projected_savings >= 0 ? (
                          <ToolTipComponent data={data?.projected_savings || 0}>
                            <span style={{ cursor: "context-menu" }}>
                              ${projected_savings_pm}
                            </span>
                          </ToolTipComponent>
                        ) : (
                          <ToolTipComponent data={data?.projected_savings || 0}>
                            <span style={{ cursor: "context-menu" }}>
                              -${projected_savings_pm}
                            </span>
                          </ToolTipComponent>
                        )}

                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#FFFFFF",
                          }}
                          className={clsx("container", styles.currunt_cost1)}
                        >
                          /m
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className={clsx("container-fluid", styles.Rec_inner_contaier_text)}
          >
            Updated Today at 7:05AM
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveCostHeader;
