import React, { useContext, useEffect, useMemo, useState } from "react";
import style from "@/page-components/RightSizing/rightSizing.module.css";
import modulestyle from "@/page-components/RI-Capacity/ri.module.css";
// import notification from "@/assets/img/notification-cross.svg";
import crossModal from "@/assets/img/modal_cross.svg";
import infoIcon from "@/assets/img/vmRec_i_icon.svg";
import { RiContext } from "@/page-components/RI-Capacity/ri.context";
import { Offcanvas, Spinner } from "react-bootstrap";
import SpecsModal from "./specs-component.part";
import { useRiModalStatics } from "@/services/ri-recommendation/getRiModalstatics.repo";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import CurrencyFormatter from "@/lib/value-converison";

const RiRecommendationsModal = (service) => {
  const context = useContext(RiContext);
  const [hideFilterSection, setHideFilterSection] = useState(false);

  const query = useMemo(() => {
    return {
      service: service.service,
      servicetype: "reserved-instance",
      subscriptionId: context.subscriptionId,
      platform: context.platform,
      resourceId: context.resourceId,
      vmFamily: context.instanceType,
      region: context.region,
    };
  }, [
    context.instanceType,
    context.platform,
    context.region,
    context.resourceId,
    context.subscriptionId,
    service,
  ]);
  const { data, isLoading, error } = useRiModalStatics(query);
  useEffect(() => {
    if (
      error &&
      (error.message === API_NO_DATA_FOUND_ERROR ||
        error.message === API_NOT_FOUND_ERROR ||
        error.message === API_LOADING_ERROR)
    ) {
      setHideFilterSection(true);
    } else {
      setHideFilterSection(false);
    }
  }, [error]);

  const handleClose = () => {
    context?.setShowRiRecommendation(false);
  };

  return (
    <>
      <>
        <Offcanvas
          show={context?.showRiRecommendation}
          placement="end"
          style={{
            backgroundColor: hideFilterSection ? "#F8F9FA" : "",
            width: "90%",
            padding: "16px",
            overflowY: "scroll",
            gap: "12px",
          }}
        >
          <div className={style.recommendationHeader}>
            <span
              style={{
                fontSize: "18px",
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
              }}
            >
              {!hideFilterSection && (
                <>
                  RI Recommendation
                  <img
                    style={{ marginLeft: "8px" }}
                    src={infoIcon}
                    width={16}
                    height={16}
                    alt="infoIcon"
                  />
                </>
              )}
            </span>
            <img
              src={crossModal}
              alt="Close Notification"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </div>

          {isLoading && (
            <div
              style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          )}
          {error && (
            <ScenariosErrorState
              error={error.message}
              messageConfig={{
                [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                  message: "No Data Available for Selected Filters",
                  additionalMessage:
                    "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
                },
                [API_NO_DATA_FOUND_ERROR]: {
                  message: "No Data Available",
                  additionalMessage:
                    "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                },
                [API_LOADING_ERROR]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
              }}
            />
          )}

          {data?.recommendations?.length > 0 && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "12px",
                }}
              >
                <div className={style.recommendationText}>
                  <p>
                    Reserved instances can provide a significant discount over
                    on-demand prices. With reserved instances, you can
                    pre-purchase the base costs for your virtual machines.
                    Discounts will automatically apply to new or existing VMs
                    that have the same size and region as your reserved
                    instance. We analyzed your usage over selected Term,
                    look-back period and recommend money-saving reserved
                    instances. 
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "space-between",
                    gap: "64px",
                    padding: "16px 15% 16px 12px",
                  }}
                >
                  <div className={modulestyle.VM_family_block}>
                    <span className={modulestyle.vm_name}>
                      {service.service === "azure"
                        ? "Instance Type"
                        : service.service === "aws"
                        ? "VM Type"
                        : "--"}
                    </span>
                    <span className={modulestyle.vm_value}>
                      {context?.instanceType || "NAN"}
                    </span>
                  </div>
                  <div className={modulestyle.VM_family_block}>
                    <span className={modulestyle.vm_name}>
                      On-Demand Hours /qtr 
                    </span>
                    <span className={modulestyle.vm_other_values}>
                      {data?.resource?.ondemand_hrs !==null ?Number(data?.resource?.ondemand_hrs).toFixed(2):"--"}h
                      {/* {Number(context?.onDemandH).toFixed(2)}h */}
                    </span>
                  </div>
                  <div className={modulestyle.VM_family_block}>
                    <span className={modulestyle.vm_name}>On-Demand Cost</span>
                    <span className={modulestyle.vm_other_values}>
                    {data?.resource?.ondemand_cost !== null?
                         <CurrencyFormatter value={data?.resource?.ondemand_cost} hideDollar={false} />:"--"}
                      {/* ${Number(context?.onDemandC).toFixed(2) || "0"} */}
                    </span>
                  </div>
                </div>
              </div>

              <SpecsModal service={service} data={data} isLoading={isLoading} />
            </>
          )}
        </Offcanvas>
      </>
    </>
  );
};

export default RiRecommendationsModal;
