import React, { useContext } from "react";
import SavingHeaderContainer from "../components/saving-header-container.part";
import SavingBarGraph from "../components/savings-bar-graph.part";
import SavingAllCloudTable from "../components/saving-all-cloud-table.part";
import subDays from "date-fns/subDays";
import { differenceInDays, format, startOfMonth, endOfMonth } from "date-fns";
import { useState, useMemo } from "react";
import { useStatisticsHeaderAzure } from "@/services/saving/getAzureHeaderV2.repo";
import {
  SavingProvider,
  SavingContext,
} from "@/page-components/savings/components/saving-context-file.part";

const AllCloudSaving = () => {
  return (
    <SavingProvider>
      <AllCloudSavingPart />
    </SavingProvider>
  );
};

const AllCloudSavingPart = () => {
  const [range, setRange] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    },
  ]);

  let fromDate = format(range[0].startDate, "yyyy-MM-dd");
  let toDate = format(range[0].endDate, "yyyy-MM-dd");

  let daysDifference = differenceInDays(toDate, fromDate);
  const context = useContext(SavingContext);

  const query = useMemo(() => {
    return {
      service: "all-cloud",
      toDate,
      fromDate,
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
      },
    };
  }, [context.selectAccounts, context.selectRegions, fromDate, toDate]);

  const { data, isLoading, error } = useStatisticsHeaderAzure(query);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 p-0">
            <SavingHeaderContainer
              service="all-cloud"
              ShowTabs={false}
              toDate={toDate}
              fromDate={fromDate}
              range={range}
              setRange={setRange}
              daysDifference={daysDifference}
              data={data}
              isLoading={isLoading}
              error={error}
            />
            <div
              className="container-fluid col-lg-12 "
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                gap: "24px",
                paddingLeft: "40px",
                paddingRight: "19px",
              }}
            >
              <div className="row w-100">
                <div className="col-12 p-0 pe-2 pt-4 d-flex gap-4 flex-column">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      paddingRight: "20px",
                    }}
                  >
                    <SavingBarGraph
                      service="all-cloud"
                      toDate={toDate}
                      fromDate={fromDate}
                    />
                    <SavingAllCloudTable
                      service="all-cloud"
                      toDate={toDate}
                      fromDate={fromDate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllCloudSaving;
