import React from "react";
import { Offcanvas, Spinner } from "react-bootstrap";
import styles from "@/components/state-change/state.module.css";
import jiraAbledIcon from "@/assets/img/jiraAbleIcon.svg";
import closeButton from "@/assets/img/closecross.svg";
import navigateImg from "@/assets/img/link-external.svg";
import ViewDiscreptionTable from "./view-discreption-table";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ViewModalTable from "./viewSnap-modalTable";
import userImage from "@/assets/img/userImage.jpg";
import clsx from "clsx";

const ViewAcceptData = ({
  type,
  show,
  handleClose,
  name,
  selectedRows,
  isLoading,
  table_account,
  error,
  selectedResourceId,
}) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View on Jira
    </Tooltip>
  );
  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      backdrop="static"
      // className={styles.offcanvas_main}
      style={{ width: "60%" }}
    >
      <>
        {isLoading && (
          <div
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
          </div>
        )}
      </>

      <>
        {!isLoading && (
          <>
            <Offcanvas.Header className={styles.offcanvas_header_main}>
              <div className={styles.offcanvas_header}>
                <div className={styles.close_box}>
                  <div className={styles.accept_via_jira}>
                    <img
                      src={jiraAbledIcon}
                      alt="Accept via Jira"
                      style={{ marginRight: "4px" }}
                    />
                    Accepted via Jira
                  </div>
                  <img
                    src={closeButton}
                    alt="Close"
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  ></img>
                </div>
                <div className={styles.title_box}>
                  <span className={styles.tittle_name}>Title</span>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className={styles.title_value}
                      style={{
                        textOverflow: "ellipsis",
                        maxWidth: "800px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      title={selectedRows?.title}
                    >
                      {selectedRows?.title || "--"}
                    </span>
                    {selectedRows?.ticket_url && (

                    <OverlayTrigger placement="bottom" overlay={renderTooltip}>
                      <img
                        src={navigateImg}
                        alt="Navigate"
                        width="16px"
                        height="16px"
                        style={{
                          marginLeft: "8px",
                          textDecorationLine: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          // if (selectedRows?.ticket_url) {
                            window.open(selectedRows.ticket_url, "_blank");
                          // }
                        }}
                      />
                    </OverlayTrigger>
                    )}
                  </div>
                </div>
                <div className={styles.ticket_box}>
                  <div className={styles.title_box}>
                    <span className={styles.ticket_idies}>Ticket ID</span>
                    <span className={styles.ticket_id_value}>
                      {selectedRows?.ticket_id || "--"}{" "}
                    </span>
                  </div>
                  <div className={styles.title_box}>
                    <span className={styles.ticket_idies}>Ticket Status</span>
                    <span className={styles.table_ticket_status}>
                      {selectedRows?.ticket_status || "--"}
                    </span>
                  </div>
                </div>
              </div>
            </Offcanvas.Header>
            <Offcanvas.Body
              className={styles.offcanvas_body_view}
              style={{
                overflow:"hidden"
                // overflowY: "auto",
                // justifyContent: "space-between",
                // padding: "16px 24px",
              }}
            >
              <div
                style={{ padding: "16px 24px 0px", width: "100%" }}
                className={styles.forscrollbar}
              >
                {name === "Snapshots" && (
                  <ViewModalTable
                  type={type}
                    jiraStatus={selectedRows?.ticket_status}
                    selectedRows={selectedRows?.resources || []}
                    selectedResourceId={selectedResourceId}
                    description={selectedRows?.comment}
                  />
                )}
                {name === "Idle Resource" && (
                  <ViewDiscreptionTable
                    selectedRows={selectedRows?.resources}
                    jira_status={selectedRows?.ticket_status || "NA"}
                    table_account={table_account}
                    description={selectedRows?.comment}
                    selectedResourceId={selectedResourceId}
                  />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  // alignContent: "flex-end",
                  padding: "24px",
                  background: "#F8F9FA",
                  width: "100%",
                }}
              >
                <span className={styles.selected_resource_des}>
                  Submitted By
                </span>
                <div className={styles.img_comment}>
                  <img src={userImage} alt="" width={34.5} height={34.5}></img>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "6px",
                    }}
                  >
                    <div className={styles.commented_details}>
                      <span className={styles.comment_name}>
                        {selectedRows?.user_name || "--"}
                      </span>
                      <span className={styles.comment_date}>
                        {selectedRows?.created_at}
                      </span>
                    </div>
                    {/* <span className={styles.comment_des}>
                      {selectedRows?.comment || "--"}
                    </span> */}
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
            <div className={styles.buttonsContainered}>
              <div></div>
              <div>
                <button
                  id={styles.AcceptId}
                  className={styles.cancleButton}
                  onClick={handleClose}
                  type="button"
                >
                  Close
                </button>
              </div>
            </div>
          </>
        )}
      </>
    </Offcanvas>
  );
};

export default ViewAcceptData;
