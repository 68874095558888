import clsx from "clsx";
import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useMemo,
  useId,
  useEffect,
} from "react";
import { FormControl, Spinner } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import styles from "./filter-search.module.css";
import { Stack } from "react-bootstrap";
import includeIcon from "@/assets/img/include.svg";
import excludeIcon from "@/assets/img/exclude.svg";
import adminstyle from "@/page-components/admin/admin.module.css";

/**
 * @typedef {(list: any | any[]) => void} OnSelect
 */

/**
 * @typedef {(list: any[]) => void} OnRemove
 */

/**
 * @typedef FilterSearchContextType
 * @property {string} searchValue
 * @property {React.Dispatch<React.SetStateAction<string>>} setSearchValue
 * @property {FilterSearchProps} props
 */

/** @type {import('react').Context<FilterSearchContextType>} */
const FilterSearchContext = createContext();

/**
 * @callback OnInclusionChange
 * @param {'include' | 'exclude'} value
 * @returns {void}
 */

/**
 * @callback OnSearchChange
 * @param {string} value
 * @returns {void}
 */

/**
 * @callback ToggleRender
 * @param {*} selectedItem
 * @returns {React.ReactElement}
 */

/**
 * @callback ItemRendererFunc
 * @param {any} item
 * @param {number} index
 * @returns {React.ReactElement}
 */

/**
 * @typedef FilterSearchProps
 * @property {any[]} options
 * @property {any[]} selectedValues
 * @property {string} displayValue
 * @property {OnSelect} onSelect
 * @property {OnRemove=} onRemove
 * @property {ToggleRender=} toggle
 * @property {React.ReactElement=} children
 * @property {boolean=} loading
 * @property {boolean=} hideSearch
 * @property {boolean=} inclusion
 * @property {OnInclusionChange=} onInclusionChange
 * @property {string=} searchFieldPlaceholder
 * @property {boolean=} disabled
 * @property {boolean=} multiselect
 * @property {ItemRendererFunc=} itemRenderer
 * @property {any} type
 * @property {OnSearchChange=} onSearchChange
 */

/**
 * @param {FilterSearchProps} props
 */
export const FilterSearch = (props) => {
  const context = useFilterSearch(props);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      context.setSearchValue("");
    }
  }, [isOpen]);

  return (
    <FilterSearchContext.Provider value={context}>
      <Dropdown
        className="w-100"
        autoClose={props.multiselect ? "outside" : undefined}
        onToggle={(open) => setIsOpen(open)}
      >
        <Dropdown.Toggle as={CustomToggle} disabled={props.disabled}>
          {props.toggle}
          {props.children}
        </Dropdown.Toggle>

        <Dropdown.Menu className={styles.dropdownMenu} as={CustomMenu}>
          {(props?.options || []).map((e, index) => (
            <DropdownItem
              key={e.id}
              active={
                props.multiselect &&
                (props.selectedValues || []).findIndex((x) => x.id === e.id) >
                  -1
              }
              eventKey={e.id}
              item={e}
              index={index}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </FilterSearchContext.Provider>
  );
};

/**
 * @typedef DropdownItemProps
 * @property {any} item
 * @property {number} index
 */

/**
 * @param {DropdownItemProps} props
 */
const DropdownItem = (props) => {
  const context = useContext(FilterSearchContext);
  const checkboxRef = useRef();

  const isSelected = useMemo(() => {
    return context.props.selectedValues.some((s) => s.id === props.item.id);
  }, [context.props.selectedValues, props.item?.id]);

  const toggleCheckbox = () => {
    if (context.props.multiselect) {
      const selectedValues = [...context.props.selectedValues];
      if (!isSelected) {
        context.props.onSelect([...selectedValues, props.item]);
      } else {
        const index = selectedValues.findIndex((s) => s.id === props.item.id);
        selectedValues.splice(index, 1);
        context.props.onRemove(selectedValues);
      }
    } else {
      context.props.onSelect(props.item);
    }
  };

  return (
    <Dropdown.Item
      className={clsx(styles.dropdown_item, isSelected && styles.selected_item)}
      eventKey={props.item.id}
      value={props.item[props.displayValue]}
      onClick={() => {
        if (checkboxRef.current) {
          toggleCheckbox();
        }
      }}
    >
      <span className={clsx("d-flex g-0", styles.dropdown_item)}>
        <FormControl
          type="checkbox"
          className={clsx("d-inline-block w-auto pe-none ", {
            "d-none": !context.props.multiselect,
            [adminstyle.marginStyle]: props.type === "admin",
          })}
          ref={checkboxRef}
          checked={isSelected}
          onChange={toggleCheckbox}
          style={{
            marginRight: "6px",
            height: "100%",
            marginTop: "2px",
          }}
        />
        {context.props.itemRenderer && context.props.itemRenderer(props.item)}
        {!context.props.itemRenderer && (
          <span
            className="col overflow-hidden text-truncate"
            title={props.item[context.props.displayValue]}
          >
            {props.item[context.props.displayValue]}
          </span>
        )}
      </span>
    </Dropdown.Item>
  );
};

const SelectAllButton = ({ filteredOptions }) => {
  const checkboxRef = useRef();
  const context = useContext(FilterSearchContext);

  const isSelected = useMemo(() => {
    return (
      filteredOptions?.length &&
      !context.props.loading &&
      context.props.selectedValues.length === filteredOptions.length
    );
  }, [
    context.props.loading,
    filteredOptions?.length,
    context.props.selectedValues.length,
  ]);

  const toggleCheckbox = () => {
    if (!isSelected) {
      context.props.onSelect(filteredOptions);
    } else {
      context.props.onRemove([]);
    }
  };

  return (
    <Dropdown.Item
      className={styles.filter_Dropdown}
      eventKey="all"
      value="all"
      onClick={() => {
        if (checkboxRef.current) {
          toggleCheckbox();
        }
      }}
    >
      <span className={clsx("d-flex g-0", styles.dropdown_item)}>
        <FormControl
          type="checkbox"
          className="d-inline-block w-auto pe-none"
          ref={checkboxRef}
          checked={isSelected}
          onChange={toggleCheckbox}
          style={{ marginRight: "6px" }}
        />
        <span className="col overflow-hidden text-truncate" title="Select All">
          Select All
        </span>
      </span>
    </Dropdown.Item>
  );
};

/**
 * @param {FilterSearchProps} props
 * @returns {FilterSearchContextType}
 */
const useFilterSearch = (props) => {
  const [searchValue, setSearchValue] = useState("");

  return {
    searchValue,
    setSearchValue,
    props,
  };
};

const CustomMenu = React.forwardRef(({ children, style, className }, ref) => {
  const context = useContext(FilterSearchContext);
  const inclusionTrue = useId();
  const inclusionFalse = useId();

  const filteredOptions = context.props.options?.filter((item) =>
    item[context?.props.displayValue]
      ?.toLowerCase()
      .includes(context.searchValue.toLowerCase())
  );
  return (
    <div
      ref={ref}
      style={style}
      className={clsx("p-0", styles.dropdown_menu, className)}
    >
      <div className={clsx("p-2 d-flex flex-column", styles.dropdown_menu)}>
        {context.props.inclusion !== undefined && (
          <div className="mb-2">
            <Stack direction="column">
              <Form.Check
                type="radio"
                id={inclusionTrue}
                checked={context.props.inclusion}
                className={styles.radio}
                label={
                  <span className="d-flex justify-content-between">
                    <span>Include</span>
                    <img src={includeIcon} alt="" />
                  </span>
                }
                onChange={() => {
                  context.props.onInclusionChange("include");
                }}
              />

              <Form.Check
                type="radio"
                id={inclusionFalse}
                className={styles.radio}
                checked={!context.props.inclusion}
                label={
                  <span className="d-flex justify-content-between">
                    <span>Exclude</span>
                    <img src={excludeIcon} alt="" />
                  </span>
                }
                onChange={() => {
                  context.props.onInclusionChange("exclude");
                }}
              />
            </Stack>
          </div>
        )}
        {!context.props.hideSearch && (
          <div className={styles.filter_search_field}>
            <Form.Control
              autoFocus
              type="search"
              className="w-100 mb-2"
              placeholder={
                context.props.searchFieldPlaceholder || "Filter Grouping"
              }
              value={context.searchValue}
              onChange={(e) => {
                context.setSearchValue(e.target.value);
                if (context.props.onSearchChange) {
                  context.props.onSearchChange(e.target.value);
                }
              }}
            />
          </div>
        )}
        <ul className="list-unstyled flex-fill overflow-y-auto">
          {context.props.loading && (
            <span className={styles.loading_text}>Loading...</span>
          )}
          {context.props.multiselect && !context.props.loading && (
            <SelectAllButton filteredOptions={filteredOptions} />
          )}
          {React.Children.toArray(children).filter((child) => {
            return (
              !context.searchValue.length ||
              (Boolean(child.props.item) &&
                child.props.item[context?.props.displayValue]
                  ?.toLowerCase()
                  .includes(context.searchValue.toLowerCase()))
            );
          })}
        </ul>
      </div>
    </div>
  );
});

const CustomToggle = React.forwardRef(
  ({ children, onClick, disabled }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        if (!disabled) {
          e.preventDefault();
          onClick(e);
        }
      }}
    >
      {children}
    </div>
  )
);

/**
 * @typedef FilterSearchToggleProps
 * @property {string=} placeholder
 * @property {string=} className
 * @property {number=} maxItems
 * @property {string=} type
 */

/**
 * @param {FilterSearchToggleProps} props
 */
export const FilterSearchToggle = (props) => {
  const context = useContext(FilterSearchContext);
  const countMax = props?.maxItems ?? 1;
  const selectedValues = context.props.options?.find(
    (e) => e.id === context.props.selectedValues[0]
  );

  return (
    <div className="filter_search_trigger">
      {!context.props.multiselect && (
        <div
          className={clsx("filter_search_text", props.className, {
            [styles.filter_search_text_selected]:
              context?.props?.selectedValues.length > 0 &&
              context?.props?.selectedValues[0],
          })}
        >
          {(context.props.selectedValues?.length >= 1 &&
            context.props.selectedValues[0].length &&
            selectedValues &&
            selectedValues[context.props.displayValue]) ||
            props.placeholder ||
            "-- Select Option --"}
        </div>
      )}

      {context.props.multiselect && (
        <div
          className={clsx("filter_search_text", props.className, {
            [styles.filter_search_text_selected]:
              context.props.selectedValues.length > 0,
          })}
          style={{ display: "flex", justifyContent: "flex-end", gap: "4px" }}
        >
          {props.type !== "admin" &&
            context.props.selectedValues?.length >= 1 && (
              <div
                className={clsx(styles.filter_search_text_selected_text)}
                title={context.props.selectedValues
                  .slice(0, countMax)
                  .map((e) => e[context.props.displayValue])
                  .join(",")}
              >
                <span className={clsx(styles.text_truncat)}>
                  {context.props.selectedValues
                    .slice(0, countMax)
                    .map((e) => e[context.props.displayValue])
                    .join(",")}
                </span>
              </div>
            )}

          {props.type === "admin" &&
            context.props.selectedValues?.length >= 1 && (
              <div
                className={clsx(
                  styles.filter_search_text_selected_text,
                  adminstyle.selectCloud_gap
                )}
              >
                {context.props.selectedValues
                  .slice(0, countMax)
                  .map((value, index) => (
                    <span key={index}>
                      <span
                        className={clsx(
                          styles.text_truncat,
                          adminstyle.selected_items
                        )}
                      >
                        {value[context.props.displayValue]}
                      </span>
                    </span>
                  ))}
              </div>
            )}

          {context.props.selectedValues?.length >= countMax + 1 && (
            <span className={styles.filter_search_text_extras}>
              +{context.props.selectedValues.length - countMax}
            </span>
          )}

          {context.props.selectedValues?.length === 0 &&
            (props.placeholder || "-- Select Option --")}
        </div>
      )}
    </div>
  );
};
