import { format } from "date-fns";
import Joi from "joi";
import { Dropdown, Spinner } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import Form from "react-bootstrap/Form";
import select from "@/assets/img/selectOption.svg";
import { useContext, useEffect, useState } from "react";
import { BudgetContext, useBudgetContext } from "../budget.context";
import styles from "./budgets.module.css";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";

export const EditAzureBudget = Joi.object({
  management_group: Joi.string().default(""),
  resource_group: Joi.string().default(""),
  scope: Joi.string().required().messages({
    "string.empty": "scope is required",
    "any.required": "scope is required",
  }),
  ACCOUNT_ID: Joi.string().default(""),
  CHARGE_TYPE: Joi.string().required().messages({
    "string.empty": "CHARGE_TYPE is required",
    "any.required": "CHARGE_TYPE is required",
  }),
  NAME: Joi.string().required().messages({
    "string.empty": "Name is required",
    "any.required": "Name is required",
  }),
  PERIOD: Joi.string()
    .valid("Monthly", "Quarterly", "Annually")
    .required()
    .messages({
      "string.empty": "Period is required",
      "any.required": "Period is required",
    }),
  START_DATE: Joi.date().required().messages({
    "any.required": "Start Date is required",
  }),
  EXPIRATION_DATE: Joi.date().required().messages({
    "any.required": "Expiration Date is required",
  }),
  AMOUNT: Joi.number().required().messages({
    "number.empty": "Amount is required",
    "number.base": "Amount must be number",
  }),
  NOTIFICATION_TYPE: Joi.string().allow("").optional(),
  Tags: Joi.string(),
  Dimension: Joi.string(),
  COMPARISON_OPERATOR: Joi.string()
    .valid("GreaterThan", "GreaterThanOrEqualTo", "EqualTo")
    .required()
    .messages({
      "string.empty": "Comparison Operator is required",
      "any.required": "Comparison Operator is required",
    }),
  THRESHOLD_TYPE: Joi.string()
    .valid("PERCENTAGE", "ABSOLUTE_VALUE", "--")
    .required()
    .messages({
      "string.empty": "Threshold Type is required",
      "any.required": "Threshold Type is required",
    }),
  THRESHOLD: Joi.number().required().messages({
    "number.empty": "THESHOLD is required",
    "any.required": "please enter the threshold number",
    "number.base": "Threshold must be number",
  }),
  ALERTS_RECEIPIENTS: Joi.array().min(1).required().messages({
    "array.min": "At least one email is required",
    "any.required": "User Email is required",
  }),
});

const prefixedNumber = Joi.extend((joi) => ({
  type: "prefixedNumber",
  base: joi.string(),
  messages: {
    "prefixedNumber.base": "white spaces are not allowed",
  },
  validate(value, helpers) {
    if (typeof value !== "string") {
      return { value, errors: helpers.error("string.base") };
    }

    // const regexPattern = /[a-zA-Z0-9\s@$!%*?&_]/;
    // if (!value.match(regexPattern)) {
    //   return { value, errors: helpers.error("prefixedNumber.base") };
    // }

    // return { value };
  },
}));

export const CreateBudgetAzure = (context) => {
  const baseSchema = {
    scope: Joi.string().required().messages({
      "string.empty": "Scope is required",
      "any.required": "Scope is required",
    }),
    CHARGE_TYPE: Joi.string().required().messages({
      "string.empty": "CHARGE_TYPE is required",
      "any.required": "CHARGE_TYPE is required",
    }),
    NAME: Joi.string().required().messages({
      "string.empty": "Name is required",
      "any.required": "Name is required",
    }),
    PERIOD: Joi.string()
      .valid("Monthly", "Quarterly", "Annually")
      .required()
      .messages({
        "string.empty": "Period is required",
        "any.required": "Period is required",
      }),
    START_DATE: Joi.date().required().messages({
      "any.required": "Start Date is required",
    }),
    EXPIRATION_DATE: Joi.date().required().messages({
      "any.required": "Expiration Date is required",
    }),
    AMOUNT: Joi.number().required().messages({
      "number.empty": "Amount is required",
      "number.base": "Amount must be number",
    }),
    NOTIFICATION_TYPE: Joi.string()
      .valid("Actual", "Forcast", "Forecasted")
      .required()
      .messages({
        "string.empty": "Notification Type is required",
        "any.required": "Notification Type is required",
      }),
    Tags: Joi.string(),
    Dimension: Joi.string(),
    COMPARISON_OPERATOR: Joi.string()
      .valid("GreaterThan", "GreaterThanOrEqualTo", "EqualTo")
      .required()
      .messages({
        "string.empty": "Comparison Operator is required",
        "any.required": "Comparison Operator is required",
      }),
    THRESHOLD_TYPE: Joi.string()
      .valid("PERCENTAGE", "ABSOLUTE_VALUE")
      .required()
      .messages({
        "string.empty": "Threshold Type is required",
        "any.required": "Threshold Type is required",
      }),
    THRESHOLD: Joi.number().required().messages({
      "number.empty": "Threshold is required",
      "any.required": "Please enter the threshold number",
      "number.base": "Threshold must be number",
    }),
    ALERTS_RECEIPIENTS: Joi.array()
      .items(
        Joi.string()
          .email({ tlds: { allow: false } })
          .messages({
            "string.email": "ALERTS_RECEIPIENTS must be a valid email",
          })
      )
      .min(1)
      .required()
      .messages({
        "array.min": "At least one email is required",
        "any.required": "User Email is required",
        "array.includes": "Each item must be a valid email",
      }),
  };

  if (context?.selectedGroupForm === "management_group") {
    baseSchema.management_group = Joi.string().required().messages({
      "string.empty": "Management Group is required",
      "any.required": "Management Group is required",
    });
    baseSchema.ACCOUNT_ID = Joi.string().default("");
    baseSchema.resource_group = Joi.string().default("");
  }
  if (context?.selectedGroupForm === "subscription") {
    baseSchema.ACCOUNT_ID = Joi.string().required().messages({
      "string.empty": "Subscription is required",
      "any.required": "Subscription is required",
    });
    baseSchema.management_group = Joi.string().default("");
    baseSchema.resource_group = Joi.string().default("");
  }
  if (context?.selectedGroupForm === "resource_group") {
    baseSchema.resource_group = Joi.string().required().messages({
      "string.empty": "Resource Group is required",
      "any.required": "Resource Group is required",
    });
    baseSchema.ACCOUNT_ID = Joi.string().required().messages({
      "string.empty": "Subscription is required",
      "any.required": "Subscription is required",
    });
    baseSchema.management_group = Joi.string().default("");
  }

  return Joi.object(baseSchema);
};

export const EditAwsBudget = Joi.object({
  scope: Joi.string().required().messages({
    "string.empty": "Scope is required",
    "any.required": "Scope is required",
  }),
  ACCOUNT_ID: Joi.string().required().messages({
    "string.empty": "Account Name is required",
    "any.required": "Account Name is required",
  }),
  CHARGE_TYPE: Joi.string().required().messages({
    "string.empty": "CHARGE_TYPE is required",
    "any.required": "CHARGE_TYPE is required",
  }),
  NAME: Joi.string().required().messages({
    "string.empty": "Name is required",
    "any.required": "Name is required",
  }),
  Tags: Joi.string(),
  Dimension: Joi.string(),
  PERIOD: Joi.string()
    .valid("DAILY", "MONTHLY", "QUARTERLY", "ANNUALLY")
    .required()
    .messages({
      "string.empty": "Period is required",
      "any.required": "Period is required",
    }),
  START_DATE: Joi.date().required().messages({
    "any.required": "Start Date is required",
  }),
  EXPIRATION_DATE: Joi.date().required().messages({
    "any.required": "Expiration Date is required",
  }),
  AMOUNT: Joi.string().required().messages({
    "string.empty": "Amount is required",
    "string.base": "Amount must be string",
  }),
  NOTIFICATION_TYPE: Joi.string()
    .valid("ACTUAL", "FORECAST")
    .required()
    .messages({
      "string.empty": "Notification Type is required",
      "any.required": "Notification Type is required",
    }),
  COMPARISON_OPERATOR: Joi.string()
    .valid("GREATER_THAN", "LESS_THAN", "EQUAL_TO")
    .required()
    .messages({
      "string.empty": "Comparison Operator is required",
      "any.required": "Comparison Operator is required",
    }),
  THRESHOLD_TYPE: Joi.string()
    .valid("PERCENTAGE", "ABSOLUTE_VALUE")
    .required()
    .messages({
      "string.empty": "Threshold Type is required",
      "any.required": "Threshold Type is required",
    }),
  THRESHOLD: Joi.number().required().messages({
    "number.empty": "Threshold is required",
    "any.required": "please enter the threshold number",
    "number.base": "Threshold must be number",
  }),
  ALERTS_RECEIPIENTS: Joi.array().min(1).required().messages({
    "array.min": "At least one email is required",
    "any.required": "User Email is required",
  }),
});

export const CreateAwsBudget = (context) =>
  Joi.object({
    scope: Joi.string().required().messages({
      "string.empty": "scope is required",
      "any.required": "scope is required",
    }),
    ACCOUNT_ID:
      context?.selectedGroupAws === "organization"
        ? Joi.string().messages({
            "string.empty": "Account Name is required",
            "any.required": "Account Name is required",
          })
        : Joi.string().required().messages({
            "string.empty": "Account Name is required",
            "any.required": "Account Name is required",
          }),

    CHARGE_TYPE: Joi.string().required().messages({
      "string.empty": "CHARGE_TYPE is required",
      "any.required": "CHARGE_TYPE is required",
    }),
    Tags: Joi.string(),
    Dimension: Joi.string(),
    NAME: Joi.string().required().messages({
      "string.empty": "Name is required",
      "any.required": "Name is required",
    }),
    PERIOD: Joi.string()
      .valid("DAILY", "MONTHLY", "QUARTERLY", "ANNUALLY")
      .required()
      .messages({
        "string.empty": "Period is required",
        "any.required": "Period is required",
      }),
    START_DATE: Joi.date().required().messages({
      "any.required": "Start Date is required",
    }),
    EXPIRATION_DATE: Joi.date().required().messages({
      "any.required": "Expiration Date is required",
    }),
    AMOUNT: Joi.string().required().messages({
      "string.empty": "Amount is required",
      "string.base": "Amount must be number",
    }),

    NOTIFICATION_TYPE: Joi.string().valid("ACTUAL", "FORECAST").messages({
      "string.empty": "Notification Type is required",
      "any.required": "Notification Type is required",
    }),
    COMPARISON_OPERATOR: Joi.string()
      .valid("GREATER_THAN", "LESS_THAN", "EQUAL_TO")
      .required()
      .messages({
        "string.empty": "Comparison Operator is required",
        "any.required": "Comparison Operator is required",
      }),
    THRESHOLD_TYPE: Joi.string()
      .valid("PERCENTAGE", "ABSOLUTE_VALUE")
      .required()
      .messages({
        "string.empty": "Threshold Type is required",
        "any.only": "Threshold Type is required",
      }),
    THRESHOLD: Joi.number().required().messages({
      "number.empty": "Threshold is required",
      "any.required": "please enter the threshold number",
      "number.base": "Threshold is required",
    }),
    ALERTS_RECEIPIENTS: Joi.array()
      .items(
        Joi.string()
          .email({ tlds: { allow: false } })
          .messages({
            "string.email": "ALERTS_RECEIPIENTS must be a valid email",
          })
      )
      .min(1)
      .required()
      .messages({
        "array.min": "At least one email is required",
        "array.base": "User Email is required",
        "array.includes": "Each item must be a valid email",
      }),
  });

export const InputFields = (props) => {
  return (
    <div className="accountid_box">
      <div className="accountid_name">
        {props.title} {props.unit ? props.unit : " "}
        <span className="astriek_css">
          <b>*</b>
        </span>
      </div>

      <div style={{ display: "flex" }}>
        {props?.disableOption === true && (
          <input
            type={props.type ? props.type : "text"}
            className="input_box_css"
            name={props.title?.toUpperCase()}
            defaultValue={props.data || "--"}
            // value={props.data || "--"}
            disabled={props?.disableOption}
            {...props.register}
          />
        )}
        {!props?.disableOption === true && (
          <input
            type={props.type ? props.type : "text"}
            className="input_box_css"
            name={props.title?.toUpperCase()}
            defaultValue={props.data || "--"}
            disabled={false}
            {...props.register}
          />
        )}
      </div>
      {props.errors && props.errors.message && (
        <span className="budgetsError">{props.errors.message}</span>
      )}
    </div>
  );
};

export const SelectField = (props) => {
  const context = useContext(BudgetContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const filterTags = (tags) => {
    if (!searchQuery) return tags;
    return tags.filter((tag) =>
      (tag.name || tag.id || "")
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  };
  const filteredAzureTags = filterTags(props.azureFieldData || []);
  const filteredAwsTags = filterTags(props.commonFieldData || []);

  const { rules } = props || {};
  const { onSelect = () => {} } = props;
  return (
    <div className="accountid_box">
      <div className="accountid_name" style={{ cursor: "not-allowed" }}>
        {props.title} {props.unit ? props.unit : " "}
        <span className="astriek_css">
          {props.title !== "Dimension" && props.title !== "Tags" && (
            <>
              <b>*</b>
            </>
          )}
        </span>
      </div>

      <div style={{ display: "flex", position: "relative" }}>
        <Controller
          control={props.control || " "}
          name={props.fieldName || " "}
          rules={rules}
          render={({ field: { value, onChange } }) => (
            <>
              <Dropdown
                className="input_box_css"
                style={{width: props?.CustomWidth === true ? "270px" : ""}}
                onSelect={(selected) => {
                  onChange(selected);
                  onSelect(selected);
                }}
                onToggle={(isOpen) => {
                  setIsDropdownOpen(isOpen);
                  if (isOpen) {
                    setSearchQuery(""); // Reset the search query when the dropdown is opened
                  }
                }}
              >
                {props?.editData && props?.disableOption === true && (
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    className="dropdown_Toggel"
                    disabled={props?.disableOption}
                  >
                    <span>{props?.editData ? props?.editData : "NA"}</span>
                  </Dropdown.Toggle>
                )}
                {props?.editData && !props?.disableOption === true && (
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    className="dropdown_Toggel"
                    disabled={false}
                  >
                    {value ? value : "NA"}
                  </Dropdown.Toggle>
                )}
                {props?.commonFieldData?.length === 1 &&
                props?.commonFieldData[0] &&
                props?.disableOptionAWs ? (
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    className="dropdown_Toggel"
                    disabled={props?.disableOptionAWs}
                  >
                    <span>{props?.commonFieldData[0]?.name}</span>
                  </Dropdown.Toggle>
                ) : (
                  props?.placeholder && (
                    <Dropdown.Toggle
                      variant="light"
                      id="dropdown-basic"
                      className="dropdown_Toggel"
                      disabled={props.disableValue}
                    >
                      <span style={!value ? { color: "#ADB5BD" } : {}}>
                        {value
                          ? (
                              props?.azureFieldData ||
                              props?.commonFieldData ||
                              []
                            ).find((e) => e.id === value)?.name
                          : props?.placeholder}
                      </span>
                    </Dropdown.Toggle>
                  )
                )}
                <Dropdown.Menu className="alligment" id="insetStyles">
                  {props.search && (
                    <Form.Control
                      autoFocus
                      type="search"
                      className="w-100 mb-2"
                      placeholder="Search tags..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  )}
                  {props?.isLoading && (
                    <>
                      <div style={{ textAlign: "center" }}>
                        <Spinner />
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      overflowY: (filteredAzureTags || filteredAwsTags).length > 15 ? "scroll" : "",
                      maxHeight: "450px",
                    }}
                  >
                    {props?.service === "azure" &&
                      filteredAzureTags.map((e) => (
                        <Dropdown.Item key={e.id} eventKey={e.id}>
                          <span className="rrhg">
                            {e?.name || e?.id || "--"}
                          </span>
                        </Dropdown.Item>
                      ))}
                    {props?.service === "aws" &&
                      filteredAwsTags.map((e) => (
                        <Dropdown.Item key={e.id} eventKey={e.id}>
                          {e?.name || e?.id || "--"}
                        </Dropdown.Item>
                      ))}

                    {/* No Results Message */}
                    {filteredAzureTags.length === 0 &&
                      filteredAwsTags.length === 0 &&
                      props?.isLoading === false && (
                        <div style={{ textAlign: "center", padding: "10px" }}>
                          No {props?.title} found.
                        </div>
                      )}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        />
        <img
          style={{ position: "absolute", right: "10px", top: "13px" }}
          src={select}
          width={"10px"}
          height={"10px"}
          alt="icon"
        />
      </div>
      <div style={{height:"10px",display:"flex"}}>
      {rules !== " " &&
        rules !== undefined &&
        rules !== null &&
        rules &&
        rules.message && <span className="budgetsError">{rules.message}</span>}
      </div>
    </div>
  );
};

export const convertToDateFormat = (inputDate) => {
  try {
    const formattedDate = format(new Date(inputDate), "yyyy-MM-dd");
    return formattedDate;
  } catch (error) {
    return "";
  }
};

export const RadioButton = ({ control, name, title, value }) => {
  const context = useContext(BudgetContext);

  return (
    <>
      <div className="radioButtonSection">
        <Controller
          name={name}
          control={control}
          defaultValue={context?.selectedGroupForm}
          render={({ field }) => (
            <>
              <input
                type="radio"
                name={name}
                id={value}
                value={value}
                checked={field?.value === value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  context?.setSelectedGroupForm(e.target.value);
                }}
              />
              <label>{title}</label>
            </>
          )}
        />
      </div>
    </>
  );
};
