import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "../components/budgets.page.css";
import addWidget from "@/assets/img/addWidget.svg";
import menu_dot from "@/assets/img/menu-dots 1.svg";
import UsedChart from "@/page-components/budgets/Aws/used.part";
import ForcastBudget from "../components/forecast.part";
import ChartDetails from "../components/chartDetail.part";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Dropdown from "react-bootstrap/Dropdown";
import download from "@/assets/img/download.svg";
import nofication from "@/assets/img/notifications_active.svg";
import notificationOff from "@/assets/img/notifications_off.svg";
import { useListApi, useListApiAzure } from "@/services/budget/setBudgetCard";
import { useCombineQuery } from "@/services/budget/setDelete";
import { useNavigate, useSearchParams } from "react-router-dom";
import ToastComponent from "../components/toast";
import styles from "../components/budgets.module.css";
import BudgetsErrorStates from "@/components/States/BudgetsErrorStates";
import Update from "../components/edit.part";
import ConformationDialog from "../components/Conformation-Dialog";
import BudgetSkeleton from "../components/skeleton.part";
import DataLoading from "@/components/States/DataLoading";
import clsx from "clsx";
import menuicon from "@/assets/img/menu.svg";
import AzureWidget from "../components/azureWidget.page";
import CustomeToastComponent from "../components/customeToastComponent";
import BudgetFilterSection from "../components/budgetFilterSection";
import { Budget_Provider, BudgetContext } from "../budget.context";
import sorting from "@/assets/img/sortingBudget.svg";
import listView from "@/assets/img/listViewBudget.svg";
import listselected from "@/assets/img/tableSelectedBudget.svg";
import cardView from "@/assets/img/cardViewBudget.svg";
import Cardselected from "@/assets/img/cardSelectedBudget.svg";
import tableselected from "@/assets/img/tableSelectedBudget.svg";
import style from "@/page-components/idle-resource/idle.module.css";
import DropdowmComponent from "@/common/DropdownComponent";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import { ProgressBar } from "react-bootstrap";
import Pagination from "@/components/pagination/pagination.component";
import TableErrorState from "@/components/States/TableErrorState";
import { useListApiAzuredownload } from "@/services/budget/getBudgetDownload.repo";
import { useFilterBudgetsTag } from "@/services/budget/getBudgetTagKeys.repo";
import CurrencyFormatter from "@/lib/value-converison";

const AzureBudgetPage = () => {
  return (
    <Budget_Provider>
      <AzureBudgetPages />
    </Budget_Provider>
  );
};

const AzureBudgetPages = () => {
  const context = useContext(BudgetContext);
  const service = "azure";
  const [isLayoverOpen, setIsLayoverOpen] = useState(false);
  const [recordId, setRecordId] = useState([]);
  const [toast, setToast] = useState(false);
  const [edit, setEdit] = useState(false);
  const [message, setMessage] = useState("");
  const [currentData, setCurrentData] = useState(null);
  const [conformation, setConformation] = useState({
    dialog: false,
    delete: false,
  });
  const [notificationOn, setNotificationOn] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedTags, setSelectedTags] = useState([
    {
      filterOn: "",
      filterType: "include",
      values: [],
    },
  ]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [budgetList, setBudgetList] = useState([]);
  const [hideFilterSection, setHideFilterSection] = useState(false);
  const [BudgetView, setbudgetView] = useState(
    () => localStorage.getItem("budgetView") || "card"
  );
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const [orderBy, setOrderBy] = useState("usedamount");
  const desc = searchParams.get("desc");

  // useEffect(() => {
  //   if (context?.selectedGroup) {
  //     searchParams.set("scope", context?.selectedGroup);
  //     setSearchParams(searchParams);
  //   }
  // }, [context?.selectedGroup, searchParams]);
  // useEffect(() => {
  //   const savedView = localStorage.getItem("budgetView");
  //   setbudgetView(savedView || "card");
  //   console.log("gettingview",savedView)
  // }, []);

  useEffect(() => {
    localStorage.setItem("budgetView", BudgetView);
  }, [BudgetView]);

  useEffect(() => {
    if (toast === false) {
      setMessage("");
    }
  }, [toast]);
  const payload = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;

    return {
      type: BudgetView,
      page: parsedPage || 1,
      orderBy: orderBy || "usedamount",
      desc: searchParams.get("desc") || "true",
      recordsPerPage: parsedItemsPerPage || 20,
      scope: context?.selectedGroup,
      tags: selectedTags,
      service: service,
      accounts:
        selectedValues?.account &&
        (selectedValues?.account || []).map((e) => e.id),
      mg:
        selectedValues?.management &&
        (selectedValues?.management || []).map((e) => e.id),
      rg:
        selectedValues?.ResourceGroup &&
        (selectedValues?.ResourceGroup || []).map((e) => e.id),
    };
  }, [
    BudgetView,
    context?.selectedGroup,
    itemsPerPage,
    orderBy,
    page,
    searchParams,
    selectedTags,
    selectedValues?.ResourceGroup,
    selectedValues?.account,
    selectedValues?.management,
  ]);

  const {
    data: listApi,
    isLoading,
    refetch,
    isSuccess: listAllSuccess,
    isError: listAllIsError,
    error: listAllError,
  } = useListApiAzure(payload);
  const { data: dimensionKeys, refetch: dimensionrefetch } =
    useFilterBudgetsTag(payload);

  useEffect(() => {
    if (
      listAllError &&
      (listAllError.message === API_NO_DATA_FOUND_ERROR ||
        listAllError.message === API_NOT_FOUND_ERROR ||
        listAllError.message === API_LOADING_ERROR ||
        listAllError.message === API_NO_DATA_FOUND_FOR_FILTERS_ERROR)
    ) {
      setHideFilterSection(true);
    } else {
      setHideFilterSection(false);
    }
  }, [listAllError]);

  const toggleLayover = () => {
    setIsLayoverOpen(true);
  };

  const closeUpdate = () => {
    setEdit(false);
  };

  const handleDownloadCsv = (index) => {
    const budgetData = listApi?.data[index];
    const csvData = [Object.keys(budgetData), Object.values(budgetData)];
    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${listApi?.data[index].BudgetName}-csv-file.csv`;
    link.click();
  };

  const refetchApi = () => {
      refetch();
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("page", "1");
      navigate(`?${searchParams.toString()}`);
  };

  const {
    mutate: Body,
    data: Details,
    isSuccess,
    isError,
    error,
    isPending,
  } = useCombineQuery();

  const handleFunctionality = (
    Id,
    name,
    managementGroupId,
    accountId,
    resourceGroup,
    status = null
  ) => {
    const notificationFn = (indication) => {
      Body({
        body: {
          ACCOUNT_ID: Id || accountId,
          managementGroup: managementGroupId,
          resourceGroup: resourceGroup,
          BudgetName: name,
          scope: context?.selectedGroup,
        },
        service: service,
        type: status === "Active" ? "snooze" : "unsnooze",
        selection: indication,
      });

      setNotificationOn((prevNotificationOn) => ({
        ...prevNotificationOn,
        [name]: !prevNotificationOn[name],
      }));
      setToast(!toast);
    };

    const singleRes = (indication) => {
      if (indication === "singleRes") {
        Body(
          {
            body: {
              subscriptionId: accountId,
              resourceGroup: resourceGroup,
              budgetName: name,
              scope: context?.selectedGroup,
              managementGroup: managementGroupId,
            },
            service: service,
            selection: indication,
          },
          {
            onError: (error) => {
              setToast((prevToast) => !prevToast);
            },
          }
        );
        setEdit((prevEdit) => !prevEdit);
      }
    };
    return {
      notificationFn,
      singleRes,
    };
  };

  useEffect(() => {
    if (isSuccess === true)
      setTimeout(() => {
        refetch();
        dimensionrefetch();
      }, 10000);
  }, [refetch, isSuccess, dimensionrefetch]);

  useEffect(() => {
    if (isSuccess === true) { 
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("page", "1");
      navigate(`?${searchParams.toString()}`);
      dimensionrefetch();}
  }, [dimensionrefetch, isSuccess, navigate]);

  const handleDelete = ({
    ACCOUNT_ID,
    NAME,
    OPERATION,
    SUBSCRIPTION_ID,
    resourceGroup,
    managementGroup,
  }) => {
    Body(
      {
        body: {
          ACCOUNT_ID: ACCOUNT_ID || SUBSCRIPTION_ID,
          resourceGroup: resourceGroup || "",
          managementGroup: managementGroup || "",
          scope: context?.selectedGroup,
          BudgetName: NAME,
        },
        service: service,
        selection: OPERATION,
      },
      {
        onError: (error) => {
          setToast((prevToast) => !prevToast);
        },
        onSuccess: () => {
          setToast(true);
        },
      }
    );
    setTimeout(() => refetch(), 10000);
  };

  useEffect(() => {
    if (isSuccess && Details !== undefined) {
      setMessage({ message: Details?.message, type: "success" });
    }
    if (isError || error) {
      setMessage({ message: error?.message, type: "Anamaly" });
    }
  }, [isSuccess, Details, isError, error, setMessage]);
  const handleUrl = (name, id, resourceGroup, managementGroup) => {
    const scope = context?.selectedGroup;
    searchParams.set("BudgetName", `${name}`);
    searchParams.set("scope", context?.selectedGroup);
    if (scope === "management_group") {
      searchParams.set("Id", managementGroup);
    } else {
      searchParams.set("Id", id);
    }
    setSearchParams(searchParams);
    const resourceGroupValue =
      scope === "resource_group" ? `&resourceGroup=${resourceGroup}` : "";
    navigate(
      `/azure/budgets/${name}/${
        scope === "management_group" ? managementGroup : id
      }?scope=${scope}${resourceGroupValue}`
    );
  };
  useEffect(() => {
    searchParams.delete("BudgetName");
    searchParams.delete("scope");
    searchParams.delete("Id");
    // searchParams.delete("page");
    const newUrl = `${window.location.pathname}${"?"+searchParams.toString()}`;
    window.history.replaceState(null, "", newUrl);
  }, [searchParams]);

  const handleDragStart = (index) => (event) => {
    event.dataTransfer.setData("text/plain", index);

    event.currentTarget.classList.add("dragging");
  };

  const handleDragOver = (event) => {
    event.preventDefault();

    event.currentTarget.classList.add("over");
  };
  const handleDragLeave = (event) => {
    event.currentTarget.classList.remove("over");
  };

  const handleDrop = (index) => (event) => {
    event.preventDefault();
    const draggedIndex = event.dataTransfer.getData("text/plain");
    const draggedItem = budgetList[draggedIndex];
    const updatedList = [...budgetList];

    updatedList.splice(draggedIndex, 1);
    updatedList.splice(index, 0, draggedItem);

    setBudgetList(updatedList);

    event.currentTarget.classList.remove("dragging", "over");
  };
  const handleDragEnd = (event) => {
    event.currentTarget.classList.remove("dragging", "over");
  };

  useEffect(() => {
    if (listApi?.data) {
      setBudgetList(listApi.data);
    }
  }, [listApi?.data]);

  const handleOrderByClick = (columnName) => {
    setOrderBy(orderBy === columnName ? columnName : columnName);
    const newBoolVal = desc === "true" ? "false" : "true";

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("orderBy", orderBy);
    updatedSearchParams.set("desc", newBoolVal);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };

  // ------------for download------------------//

  const items = [
    {
      eventKey: "Download as CSV",
      icon: download,
      label: "Download as CSV",
    },
  ];
  const downloadQuery = useMemo(() => {
    return {
      scope: context?.selectedGroup,
      service: service,
      tableName: "download",
    };
  }, [context?.selectedGroup]);
  const { mutate: downloadMutate } = useListApiAzuredownload(downloadQuery);
  const handleDownloadClick = (eventKey) => {
    if (eventKey === "Download as CSV") {
      downloadMutate(downloadQuery);
    }
  };
  const handleTagSelection = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <>
      {toast && (
        <ToastComponent message={message} toast={toast} setToast={setToast} />
      )}
      <div
        className="budget_main_container"
        style={{
          height: hideFilterSection ? "100vh" : "",
          marginBottom: "-33px",
        }}
      >
        <div className="budget_container" style={{ height: "100vh" }}>
          <div className="budgets_header">
            <div className="budget_head">
              <span className="head_text">
                Budgets
                <span style={{ fontWeight: "400", fontSize: "21px" }}>
                  {context?.selectedGroup === "management_group"
                    ? " (Management Group)"
                    : context?.selectedGroup === "resource_group"
                    ? " (Resource Group)"
                    : context?.selectedGroup === "subscription"
                    ? " (Subscription)"
                    : ""}
                </span>
              </span>

              <span className="head_paragraph">
                This dashboard allows you to create, update or delete a budget.
                It also enables you to turn notification on or off for budget
                alerts.
              </span>
            </div>
            <div className={styles.cardListContainer}>
              <div className="btn add_new_widgets" onClick={toggleLayover}>
                <img className="add_widgte_svg" src={addWidget} alt=" " />
                <span className="add_widget_text">Create Budgets</span>
              </div>
              <span className={styles.cardListView}>
                <span
                  style={{
                    borderRadius: "8px 0px 0px 8px",
                    transition: "background 0.3s ease-in-out",
                    background:
                      BudgetView === "card" ? "#EDEFF2" : "transparent",
                  }}
                  className={styles.buttonView}
                  onClick={() => setbudgetView("card")}
                >
                  <img
                    src={BudgetView === "card" ? Cardselected : listView}
                    alt="Card View"
                  />
                </span>
                <span
                  style={{
                    borderRadius: "0px 8px 8px 0px",
                    transition: "background 0.3s ease-in-out",
                    background:
                      BudgetView === "list" ? "#EDEFF2" : "transparent",
                  }}
                  className={styles.buttonView}
                  onClick={() => {
                    setbudgetView("list");
                    handleTagSelection();
                  }}
                >
                  <img
                    src={BudgetView === "list" ? listselected : cardView}
                    alt="List View"
                  />
                </span>
              </span>
            </div>
          </div>

          {BudgetView === "card" && (
            <>
              {isLoading && (
                <>
                  <div style={{ margin: "0px" }}>
                    <BudgetsErrorStates />
                  </div>
                </>
              )}
              {listAllError && (
                <ScenariosErrorState
                  error={listAllError.message}
                  messageConfig={{
                    [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                      message: "No Data Available for Selected Filters",
                      additionalMessage:
                        "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
                    },
                    [API_NO_DATA_FOUND_ERROR]: {
                      message: "No Data Available",
                      additionalMessage:
                        "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                    },
                    [API_LOADING_ERROR]: {
                      message: "Oops!",
                      additionalMessage:
                        "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                    },
                    [API_NOT_FOUND_ERROR]: {
                      message: "404 Error",
                      additionalMessage:
                        "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                    },
                  }}
                />
              )}
              {!isLoading && !(listAllError || listAllIsError) && (
                <div
                  className="chart_conatiner"
                  style={{ overflowY: "scroll", height: "100vh" }}
                >
                  {budgetList.length === 0 && (
                    <BudgetSkeleton functionality={toggleLayover} />
                  )}
                  {budgetList.map((data, index) => (
                    <div
                      key={index}
                      className="chart_card"
                      draggable
                      onDragStart={handleDragStart(index)}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop(index)}
                      onDragEnd={handleDragEnd}
                    >
                      <div className="sandbox-budget">
                        <span
                          className="text_sandbox elipese"
                          style={{ maxWidth: "195px" }}
                          onClick={() =>
                            handleUrl(
                              data?.budgetName,
                              data?.accountId,
                              data?.resourceGroup,
                              data?.managementGroupId
                            )
                          }
                        >
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip
                                id="tooltip"
                                className={style.customTooltip}
                              >
                                {data?.budgetName}
                              </Tooltip>
                            }
                          >
                            <span>{data.budgetName}</span>
                          </OverlayTrigger>
                        </span>
                        <div style={{ display: "flex", gap: "12px" }}>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              // <Tooltip id={index}>
                              //   {notificationOn[data.NAME]
                              //     ? "Notification On"
                              //     : "Notification Off"}
                              // </Tooltip>
                              <Tooltip id={index}>
                                {data?.status === "Snoozed"
                                  ? "Notification Off"
                                  : "Notification On"}
                              </Tooltip>
                            }
                          >
                            <img
                              className="notification_bell"
                              id={index}
                              src={
                                data.status === "Snoozed"
                                  ? notificationOff
                                  : nofication
                              }
                              alt=""
                              onClick={() =>
                                handleFunctionality(
                                  data?.AccountId,
                                  data?.budgetName,
                                  data?.managementGroupId,
                                  data?.accountId,
                                  data?.resourceGroup,
                                  data?.status
                                ).notificationFn("notification")
                              }
                            />
                          </OverlayTrigger>
                          <Dropdown id="budgetId">
                            <Dropdown.Toggle
                              variant="light"
                              id={styles.custom_dropdown_toggle}
                              className={clsx(
                                styles.Dropdown_Toggel,
                                styles.custom_dropdown_toggle
                              )}
                              style={{ border: "none" }}
                            >
                              <img src={menu_dot} className="pb-2" alt="menu" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className={styles.dropdown_menu}>
                              <Dropdown.Item
                                className={styles.budget_dropdown_item}
                                onClick={() =>
                                  handleFunctionality(
                                    data?.AccountId,
                                    data?.budgetName,
                                    data?.managementGroupId,
                                    data?.accountId,
                                    data?.resourceGroup
                                  ).singleRes("singleRes")
                                }
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                className={styles.budget_dropdown_item}
                                onClick={() => {
                                  setCurrentData({
                                    ACCOUNT_ID: data?.AccountId,
                                    SUBSCRIPTION_ID: data?.accountId,
                                    NAME: data?.budgetName,
                                    managementGroup: data?.managementGroupId,
                                    resourceGroup: data?.resourceGroup,
                                    OPERATION: "delete",
                                  });
                                  setConformation((prev) => ({
                                    ...prev,
                                    dialog: true,
                                    delete: false,
                                  }));
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                              {/* <Dropdown.Divider />
                              <Dropdown.Item
                                className={styles.budget_dropdown_item}
                                eventKey="Download as CSV"
                                onClick={() => handleDownloadCsv(index)}
                              >
                                <img
                                  src={download}
                                  alt=""
                                  style={{ marginRight: "10px" }}
                                />
                                Download as CSV
                              </Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <UsedChart
                        service={service}
                        chartdata={data}
                        index={index}
                      />
                      <ForcastBudget forcastdata={data} index={index} />
                      <ChartDetails chartDetails={data} index={index} />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
          {BudgetView === "list" && (
            <>
              {isLoading && (
                <>
                  <div style={{ margin: "0px" }}>
                    <TableErrorState />
                  </div>
                </>
              )}
              {listAllError && (
                <ScenariosErrorState
                  error={listAllError.message}
                  messageConfig={{
                    [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                      message: "No Data Available for Selected Filters",
                      additionalMessage:
                        "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
                    },
                    [API_NO_DATA_FOUND_ERROR]: {
                      message: "No Data Available",
                      additionalMessage:
                        "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                    },
                    [API_LOADING_ERROR]: {
                      message: "Oops!",
                      additionalMessage:
                        "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                    },
                    [API_NOT_FOUND_ERROR]: {
                      message: "404 Error",
                      additionalMessage:
                        "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                    },
                  }}
                />
              )}
              {!isLoading && !(listAllError || listAllIsError) && (
                <div className="container-fluid  p-0">
                  <div style={{ minWidth: "30%" }}>
                    <table
                      className="table table-hover"
                      id={styles.customers}
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th
                            className={clsx(styles.th_class)}
                            style={{ width: "4%" }}
                          ></th>
                          <th
                            className={clsx(styles.th_class)}
                            style={{ width: "20%" }}
                          >
                            Name
                            <img
                              style={{ marginLeft: "4px", cursor: "pointer" }}
                              src={sorting}
                              alt=""
                              className={clsx(
                                searchParams.get("desc") === "false" &&
                                  searchParams.get("orderBy") ===
                                    "budgetname" &&
                                  styles.sorting_icon,
                                searchParams.get("desc") === "true" &&
                                  searchParams.get("orderBy") ===
                                    "budgetname" &&
                                  styles.sortingUp
                              )}
                              onClick={() => handleOrderByClick("budgetname")}
                            ></img>
                          </th>
                          <th className={clsx(styles.th_class)}>
                            Budget
                            <img
                              style={{ marginLeft: "4px", cursor: "pointer" }}
                              src={sorting}
                              alt=""
                              className={clsx(
                                searchParams.get("desc") === "false" &&
                                  searchParams.get("orderBy") === "amount" &&
                                  styles.sorting_icon,
                                searchParams.get("desc") === "true" &&
                                  searchParams.get("orderBy") === "amount" &&
                                  styles.sortingUp
                              )}
                              onClick={() => handleOrderByClick("amount")}
                            ></img>
                          </th>
                          <th
                            className={clsx(styles.th_class)}
                            style={{ width: "25%" }}
                          >
                            Current Spend
                            <img
                              style={{ marginLeft: "4px", cursor: "pointer" }}
                              src={sorting}
                              alt=""
                              className={clsx(
                                searchParams.get("desc") === "false" &&
                                  searchParams.get("orderBy") ===
                                    "usedamount" &&
                                  styles.sorting_icon,
                                searchParams.get("desc") === "true" &&
                                  searchParams.get("orderBy") ===
                                    "usedamount" &&
                                  styles.sortingUp
                              )}
                              onClick={() => handleOrderByClick("usedamount")}
                            ></img>
                          </th>
                          <th
                            className={clsx(styles.th_class)}
                            style={{ textAlign: "end" }}
                          >
                            Forecasted
                            <img
                              style={{ marginLeft: "4px", cursor: "pointer" }}
                              src={sorting}
                              alt=""
                              className={clsx(
                                searchParams.get("desc") === "false" &&
                                  (searchParams.get("orderBy") ===
                                    "forecastedamount" ||
                                    "") &&
                                  styles.sorting_icon,
                                searchParams.get("desc") === "true" &&
                                  searchParams.get("orderBy") ===
                                    "forecastedamount" &&
                                  styles.sortingUp
                              )}
                              onClick={() =>
                                handleOrderByClick("forecastedamount")
                              }
                            ></img>
                          </th>
                          <th className={clsx(styles.th_class)}>Periodicity</th>
                          <th
                            className={clsx(styles.th_class)}
                            style={{ textAlign: "start" }}
                          >
                            Start Date
                            <img
                              style={{ marginLeft: "4px", cursor: "pointer" }}
                              src={sorting}
                              alt=""
                              className={clsx(
                                searchParams.get("desc") === "false" &&
                                  (searchParams.get("orderBy") ===
                                    "startdate" ||
                                    "") &&
                                  styles.sorting_icon,
                                searchParams.get("desc") === "true" &&
                                  searchParams.get("orderBy") === "startdate" &&
                                  styles.sortingUp
                              )}
                              onClick={() => handleOrderByClick("startdate")}
                            ></img>
                          </th>
                          <th
                            className={clsx(styles.th_class)}
                            style={{ textAlign: "center" }}
                          >
                            Threshold
                          </th>
                          <th
                            style={{
                              width: "4%",
                              background: "#f6f6f6",
                              textAlign: "center",
                            }}
                          >
                            <DropdowmComponent
                              menuIcon={menuicon}
                              items={items}
                              onItemClick={handleDownloadClick}
                            />
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {(listApi?.data || []).map((item, index) => {
                          const barColor = clsx("forcastPercentageColor", {
                            forcastPercentageExcededColor:
                              item?.usedAmount > item?.amount,
                          });
                          const useageDataPercentage = Number(
                            (
                              ((item?.usedAmount || item?.UsedAmount || 0) /
                                Number(item?.amount || item?.Amount || 0)) *
                              100
                            )?.toFixed(2)
                          );
                          return (
                            <tr
                              key={index}
                              className={clsx(style.table_row, "p-0")}
                            >
                              <td className={style.table_data__Class}>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id={index}>
                                      {item?.status === "Snoozed"
                                        ? "Notification Off"
                                        : "Notification On"}
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    className="notification_bell"
                                    id={index}
                                    src={
                                      item?.status === "Snoozed"
                                        ? notificationOff
                                        : nofication
                                    }
                                    alt=""
                                    onClick={() =>
                                      handleFunctionality(
                                        item?.AccountId,
                                        item?.budgetName,
                                        item?.managementGroupId,
                                        item?.accountId,
                                        item?.resourceGroup,
                                        item?.status
                                      ).notificationFn("notification")
                                    }
                                  />
                                </OverlayTrigger>
                              </td>
                              <td
                                style={{ color: "#056EE5", cursor: "pointer" }}
                                className={style.table_data__Class}
                                onClick={() =>
                                  handleUrl(
                                    item?.budgetName,
                                    item?.accountId,
                                    item?.resourceGroup,
                                    item?.managementGroupId
                                  )
                                }
                              >
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip
                                      id="tooltip"
                                      className={style.customTooltip}
                                    >
                                      {item?.budgetName}
                                    </Tooltip>
                                  }
                                >
                                  <span>{item?.budgetName || "--"}</span>
                                </OverlayTrigger>
                              </td>
                              <td className={style.table_data__Class}>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip
                                      id="tooltip"
                                      className={style.customTooltip}
                                    >
                                      {item?.amount || item?.Amount}
                                    </Tooltip>
                                  }
                                >
                                  <span>
                                    {item?.amount ? (
                                      <CurrencyFormatter
                                        value={item?.amount}
                                        hideDollar={false}
                                      />
                                    ) : (
                                      "--"
                                    )}
                                  </span>
                                </OverlayTrigger>
                              </td>
                              <td className={style.table_data__Class}>
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <h1
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "#212224",
                                    }}
                                  >
                                    {useageDataPercentage}%
                                  </h1>
                                  <h1
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      color: "#495057CC",
                                    }}
                                  >
                                    {item?.usedAmount || item?.UsedAmount ? (
                                      <CurrencyFormatter
                                        value={
                                          item?.usedAmount ||
                                          item?.UsedAmount ||
                                          0
                                        }
                                        hideDollar={false}
                                      />
                                    ) : (
                                      "--"
                                    )}
                                  </h1>
                                </span>
                                <ProgressBar style={{ height: "8px" }}>
                                  <ProgressBar
                                    className={barColor}
                                    now={useageDataPercentage}
                                    key={1}
                                  />
                                </ProgressBar>
                              </td>
                              <td
                                className={style.table_data__Class}
                                style={{
                                  textAlign: "end",
                                  paddingRight: "10px",
                                }}
                              >
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip
                                      id="tooltip"
                                      className={style.customTooltip}
                                    >
                                      {item?.forecastedAmount || "--"}
                                    </Tooltip>
                                  }
                                >
                                  <span>

                                    {item?.forecastedAmount ?<CurrencyFormatter
                                        value={
                                          item?.forecastedAmount||
                                          0
                                        }
                                        hideDollar={false}
                                      />:"--"}
                                  </span>
                                </OverlayTrigger>
                              </td>
                              <td className={style.table_data__Class}>
                                {item?.timeUnit || "--"}
                              </td>
                              <td
                                className={style.table_data__Class}
                                style={{ textAlign: "start" }}
                              >
                                {item?.startDate || "--"}
                              </td>
                              <td
                                className={style.table_data__Class}
                                style={{ textAlign: "center" }}
                              >
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip
                                      id="tooltip"
                                      className={style.customTooltip}
                                    >
                                      {item?.threshold}
                                    </Tooltip>
                                  }
                                >
                                  <span>
                                    {item?.threshold &&
                                      (item?.threshold || "--") + "%"}
                                  </span>
                                </OverlayTrigger>
                              </td>
                              <td>
                                <Dropdown id="budgetId">
                                  <Dropdown.Toggle
                                    variant="light"
                                    id={styles.custom_dropdown_toggle}
                                    className={clsx(
                                      styles.Dropdown_Toggel,
                                      styles.custom_dropdown_toggle
                                    )}
                                    style={{ border: "none" }}
                                  >
                                    <img
                                      src={menu_dot}
                                      className="pb-2"
                                      alt="menu"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    className={styles.dropdown_menu}
                                  >
                                    <Dropdown.Item
                                      className={styles.budget_dropdown_item}
                                      onClick={() =>
                                        handleFunctionality(
                                          item?.AccountId,
                                          item?.budgetName,
                                          item?.managementGroupId,
                                          item?.accountId,
                                          item?.resourceGroup
                                        ).singleRes("singleRes")
                                      }
                                    >
                                      Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className={styles.budget_dropdown_item}
                                      onClick={() => {
                                        setCurrentData({
                                          ACCOUNT_ID: item?.AccountId,
                                          SUBSCRIPTION_ID: item?.accountId,
                                          NAME: item?.budgetName,
                                          managementGroup:
                                            item?.managementGroupId,
                                          resourceGroup: item?.resourceGroup,
                                          OPERATION: "delete",
                                        });
                                        setConformation((prev) => ({
                                          ...prev,
                                          dialog: true,
                                          delete: false,
                                        }));
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                    {/* <Dropdown.Divider />
                                    <Dropdown.Item
                                      className={styles.budget_dropdown_item}
                                      eventKey="Download as CSV"
                                      onClick={() => handleDownloadCsv(index)}
                                    >
                                      <img
                                        src={download}
                                        alt=""
                                        style={{ marginRight: "10px" }}
                                      />
                                      Download as CSV
                                    </Dropdown.Item> */}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <Pagination totalItems={listApi?.total_count || 0} />
                </div>
              )}
            </>
          )}
        </div>
        <BudgetFilterSection
          name={"Subscription"}
          service={service}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          dimensionKeys={dimensionKeys}
        />
      </div>
      <AzureWidget
        selectedValues={selectedValues}
        refetch={refetchApi}
        description={"Select Your Subscription and Enhance Budget Precision"}
        onClose={setIsLayoverOpen}
        onOpen={isLayoverOpen}
        service={service}
        Title={"Create Budget"}
        setRecordId={setRecordId}
        recordId={recordId}
        name="Subscription"
        alert="Alert"
        loading={isPending}
      />
      {edit && (
        <Update
          edit={edit}
          description={"Select Your Subscription and Enhance Budget Precision"}
          loading={isPending}
          setEdit={setEdit}
          editData={Details}
          onClose={closeUpdate}
          service={service}
          Title={"Edit Budget"}
          setRecordId={setRecordId}
          recordId={recordId}
          name="Subscription"
          alert="Alert"
          setCurrentData={setCurrentData}
          setConformation={setConformation}
        />
      )}
      {conformation.dialog && (
        <div className={styles.customeOverlay}>
          <div className={styles.conformationBox}>
            <ConformationDialog
              setConformation={setConformation}
              setToast={setToast}
              handleDelete={() => handleDelete(currentData)}
              refetch={refetchApi}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AzureBudgetPage;
