import React, { useContext, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styles from "@/page-components/search-module/search.module.css";
import { SearchContext } from "../search-context";
import { useCostTrend } from "@/services/costExplorer/getCostTrendSearch.repo";
import { TableComponent } from "@/page-components/search-module/search-components/table-component.part";
import BarGraphErrorStates from "@/components/States/BarGraphErrorStates";
import SearchTableState from "@/components/States/searchTableState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import tooltipStyles from "@/page-components/idle-resource/idle.module.css";
import CurrencyFormatter from "@/lib/value-converison";
import clsx from "clsx";
import { compareAsc, parse } from "date-fns";

const BarGraphSearch = ({ service }) => {
  const context = useContext(SearchContext);
  const query = useMemo(() => {
    return {
      service: service,
      accounts: context?.selectAccounts?.length
        ? context.selectAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectRegions?.length
        ? context.selectRegions.map((e) => e.id)
        : undefined,
      name: context?.name,
      type: context?.type,
      spendingBy: context.filterMapping[context?.filteredData] || "account",
      fromDate: context?.fromDate,
      toDate: context?.toDate,
      serviceName:
        context?.type === "resource" ? context?.serviceName : undefined,
    };
  }, [
    context.filterMapping,
    context?.filteredData,
    context?.fromDate,
    context?.name,
    context.selectAccounts,
    context.selectRegions,
    context?.serviceName,
    context?.toDate,
    context?.type,
    service,
  ]);

  const { data, isLoading, error } = useCostTrend(query);

  // const sortedCostTrends = useMemo(() => {
  //   if (!data || !data.costTrends) return [];

  //   return [...data.costTrends].sort((a, b) => {
  //     const aDate = parse(a.service, "MMM, yy", new Date());
  //     const bDate = parse(b.service, "MMM, yy", new Date());
  //     return compareAsc(aDate, bDate);
  //   });
  // }, [data]);

  // console.log("sortedCostTrends", sortedCostTrends);

  const graphData = useMemo(() => {
    if (!data || !data.costTrends) return [];
    return data.costTrends.map((item) => ({
      name: item.service,
      [context?.name]: item.number,
    }));
  }, [data]);

  return (
    <>
      {isLoading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <BarGraphErrorStates />
          {context?.type !== "resource" && <SearchTableState />}
        </div>
      )}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
              message: "No Data for the Selected Date Range",
              additionalMessage:
                "The selected date range doesn’t contain any savings data. Try choosing a different range to view available savings.",
            },
            [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
              message: "No Data Available for Selected Filters",
              additionalMessage:
                "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
          }}
        />
      )}
      {data?.costTrends.length > 0 && (
        <div className={styles.bar_table_css}>
          <div className={styles.bar_div}>
            <div className={styles.align_center}>
              <span className={styles.cost_trend}>Costs Trend</span>
              <span className={styles.LDaysDiv}>
                Last {context?.monthDifference} Months
              </span>
            </div>

            <ResponsiveContainer height={250} width="100%">
              <BarChart
                data={graphData}
                margin={{ top: 0, right: 3, left: 0, bottom: 0 }}
              >
                <XAxis
                  dataKey="name"
                  tickLine={false}
                  axisLine={false}
                  fontSize={10}
                  fontWeight={500}
                  stroke="#495057"
                />
                <YAxis
                  label={{ fill: "#495057" }}
                  tickLine={false}
                  axisLine={false}
                  fontSize={10}
                  fontWeight={400}
                  stroke="#495057"
                />
                <CartesianGrid strokeDasharray="0 0" stroke="#F0F0F0" />
                <Tooltip
                  content={customTooltip}
                  cursor={{ fill: "transparent" }}
                />
                <Legend
                  align="center"
                  verticalAlign="bottom"
                  layout="horizontal"
                  iconSize={12}
                  iconType="Square"
                  fontSize={12}
                  fontWeight={400}
                  fontFamily="Inter,sans-serif"
                  fontStyle="normal"
                />
                <Bar
                  dataKey={context?.name}
                  fill="#056EE5"
                  barSize={80}
                  name={context?.title}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
          {context?.type !== "resource" && (
            <TableComponent data1={data} service={service} />
          )}
        </div>
      )}
    </>
  );
};

export default BarGraphSearch;

const customTooltip = ({ active, payload, label, name }) => {
  if (active) {
    return (
      <div className={tooltipStyles.custom_tooltip} style={{ width: "275px" }}>
        <div
          className={tooltipStyles.tooltip_date}
          style={{ paddingLeft: "8px" }}
        >
          <div className={tooltipStyles.tooltip_date_css}>{label}</div>
        </div>
        <div style={{ width: "92%", paddingLeft: "8px" }}>
          <table style={{ width: "100%", paddingRight: "24px" }}>
            <tbody>
              {payload.map((entry, index) => (
                <tr
                  key={`${tooltipStyles.tooltip_row} ${index}`}
                  className={styles.horizontalline_tool}
                  style={{
                    position: "relative",
                  }}
                >
                  {index !== payload.length - 1 && (
                    <span
                      style={{
                        content: '""',
                        position: "absolute",
                        bottom: "0",
                        left: "8px",
                        width: "100%",
                        borderBottom: "1px solid #373737",
                      }}
                    ></span>
                  )}
                  <td
                    className={tooltipStyles.cloud_cell}
                    style={{ whiteSpace: "nowrap", display: "flex" }}
                  >
                    <div
                      className={clsx(
                        styles.tooltipbox,
                        tooltipStyles.cloud_logo
                      )}
                      style={{
                        background: entry.fill,
                        width: "12px",
                        height: "12px",
                      }}
                    ></div>

                    <span style={{textOverflow:"ellipsis", textWrap:"nowrap", maxWidth:"130px",overflow:"hidden"}}>{entry.name}</span>
                  </td>
                  <td
                    className={tooltipStyles.cost_cell}
                    style={{ paddingRight: "24px" }}
                  >
                    <CurrencyFormatter value={entry?.value} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return null;
};
