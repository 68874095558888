/** @type {import('./global')} */
import { useAcceptDismissIdle } from "@/services/IdleResources/addAcceptDismissIdle.repo";
import React, { createContext, useState, useEffect } from "react";
import { differenceInDays, differenceInMonths, format, parse } from "date-fns";
import subDays from "date-fns/subDays";

/**
 * @typedef {import('@/services/IdleResources/getAllStatics.repo').GetAllStatisticsFilters} TagItem
 */

/**
 * @typedef {import('@/services/IdleResources/addAcceptDismissIdle.repo').GetRecommendationsListRes} GetRecommendationsListRes
 */

/**
 * @typedef {import('@/services/IdleResources/addAcceptDismissIdle.repo').AddAcceptDismissIdleReq} AddAcceptDismissIdleReq
 */

/**
 * @typedef IdleContextType
 * @property {string[]} selectedOption
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedOption
 * @property {string[]} dropdownView
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setDropdownView
 * @property {string[]} selectAccounts
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectAccounts
 * @property {string[]} selectRegions
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectRegions
 * @property {string[]} selectedResourceGroup
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedResourceGroup
 * @property {TagItem[]} selectedTags
 * @property {React.Dispatch<React.SetStateAction<TagItem[]>>} setSelectedTags
 * @property {TagItem[]} showJiraModal
 * @property {React.Dispatch<React.SetStateAction<TagItem[]>>} setShowJiraModal
 * @property {TagItem[]} showRetainModal
 * @property {React.Dispatch<React.SetStateAction<TagItem[]>>} setShowRetainModal
 * @property {TagItem[]} selectMonth
 * @property {React.Dispatch<React.SetStateAction<TagItem[]>>} setSelectMonth
 * @property {TagItem[]} selectedResourceId
 * @property {React.Dispatch<React.SetStateAction<TagItem[]>>} setSelectedResourceId
 * @property {GetRecommendationsListRes} addAcceptDismissIdleData
 * @property {UseMutateFunction<GetRecommendationsListRes, Error, AddAcceptDismissIdleReq>} addAcceptDismissIdle
 * @property {Error=} acceptDismissIdleError
 * @property {() => void} idleReset
 * @property {TagItem[]} selectedStatus
 * @property {React.Dispatch<React.SetStateAction<TagItem[]>>} setSelectedStatus
 * @property {TagItem[]} acceptViaJira
 * @property {React.Dispatch<React.SetStateAction<TagItem[]>>} setAcceptViaJira
 * @property {string[]} range
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setRange
 * @property {string[]} selectMonthRange
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectMonthRange
 * @property {string[]} selectedDate
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedDate
 * @property {string[]} semiCardRefetch
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSemiCardRefetch
 */

/**
 * @type {React.Context<IdleContextType>}
 */
export const IdleContext = createContext();

/**
 * @returns {IdleContextType}
 */
export const useIdleContext = () => {

  /** @type {UseState<string[]>} */
  const [selectedOption, setSelectedOption] = useState("Active");

  /** @type {UseState<string[]>} */
  const [dropdownView, setDropdownView] = useState("main");
  
  /** @type {UseState<string[]>} */

  const [selectAccounts, setSelectAccounts] = useState([]);

   /** @type {UseState<string[]>} */
   const [selectMonth, setSelectMonth] = useState(format(new Date(), "MMMM"));

  /** @type {UseState<string[]>} */
  const [selectRegions, setSelectRegions] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedResourceGroup, setSelectedResourceGroup] = useState([]);

  /** @type {UseState<string[]>} */
  const [showUntaggedOnly, setShowUntaggedOnly] = useState(false);

  /** @type {UseState<string[]>} */
  const [selectedStatus, setSelectedStatus] = useState("active");

  /** @type {UseState<string[]>} */
  const [showJiraModal, setShowJiraModal] = useState(false);

  /** @type {UseState<string[]>} */
  const [acceptViaJira, setAcceptViaJira] = useState(false);

  /** @type {UseState<string[]>} */
  const [showRetainModal, setShowRetainModal] = useState(false);

  /** @type {UseState<string[]>} */
  const [selectedResourceId, setSelectedResourceId] = useState(null);

    /** @type {UseState<string[]>} */
    const [selectedDate, setSelectedDate] = useState(null);

  /** @type {UseState<string[]>} */
  const [selectMonthRange, setSelectMonthRange] = useState("3month");

    /** @type {UseState<string[]>} */
  const [semiCardRefetch, setSemiCardRefetch] = useState(null);



  /** @type {UseState<TagItem[]>} */
  const [selectedTags, setSelectedTags] = useState([
    {
      filterOn: "",
      filterType: "include",
      values: [],
    },
  ]);

  const [value, setValue] = useState([undefined, undefined]);

  const [eventkey, setEventkey] = useState("Idle Resources");

    /** @type {UseState<string[]>} */
    const [range, setRange] = useState([
      {
        startDate: "",
        endDate: "",
        key: "selection",
      },
    ]);
    
    let fromDate = range[0].startDate ? format(range[0].startDate, "yyyy-MM-dd") : "";
    let toDate = range[0].endDate ? format(range[0].endDate, "yyyy-MM-dd") : "";
  
    const date2 = parse(toDate, "yyyy-MM-dd", new Date());
    const date1 = parse(fromDate, "yyyy-MM-dd", new Date());
    const daysDifference = differenceInDays(date2, date1) + 1;
    const monthDifference = differenceInMonths(date2, date1) +1


  useEffect(() => {
    setSelectedResourceGroup([]);
  }, [selectAccounts]);

  const {
    data: addAcceptDismissIdleData,
    mutate: addAcceptDismissIdle,
    error: acceptDismissIdleError,
    isError: checking,
    reset: idleReset,
  } = useAcceptDismissIdle();

  return {
    value,
    setValue,
    eventkey,
    setEventkey,
    selectAccounts,
    setSelectAccounts,
    selectRegions,
    setSelectRegions,
    selectedTags,
    setSelectedTags,
    addAcceptDismissIdleData,
    addAcceptDismissIdle,
    acceptDismissIdleError,
    selectedResourceGroup,
    setSelectedResourceGroup,
    checking,
    idleReset,
    showUntaggedOnly,
    setShowUntaggedOnly,
    selectedStatus,
    setSelectedStatus,
    showJiraModal,
    setShowJiraModal,
    acceptViaJira,
    setAcceptViaJira,
    showRetainModal,
    setShowRetainModal,
    selectedResourceId,
    setSelectedResourceId,
    selectMonth,
    setSelectMonth,
    selectedOption,
    setSelectedOption,
    dropdownView,
    setDropdownView,
    range,
    setRange,
    fromDate,
    toDate,
    daysDifference,
    monthDifference,
    selectMonthRange,
    setSelectMonthRange,
    selectedDate,
    setSelectedDate,
    semiCardRefetch,
    setSemiCardRefetch
  };
};

export const IdleProvider = ({ children }) => {
  const context = useIdleContext();
  return (
    <>
      <IdleContext.Provider value={context}>{children}</IdleContext.Provider>
    </>
  );
};
