import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

// {
//     "data": {
//         "total_savings": {
//             "count": 10,
//             "cost": 191.20151045140224
//         },
//         "idle_resources_saving": {
//             "count": 6,
//             "cost": 122.85227539140224
//         },
//         "right_sizing_saving": {
//             "count": 1,
//             "cost": 67.89
//         },
//         "snapshot_saving": {
//             "count": 3,
//             "cost": 0.45923506000000014
//         },
//         "total_projected_savings": {
//             "count": 26,
//             "cost": 109.62058625047288
//         },
//         "saving_till_date": 573.7602143023073
//     }
// }

/**
 * @typedef AllHeaderStatics
 * @property {AllcurrentStatics} data
 */

/**
 * @typedef AllcurrentStatics
 * @property {total_savings} total_savings
 * @property {idle_resources_saving} idle_resources_saving
 * @property {right_sizing_saving} right_sizing_saving
 * @property {snapshot_saving} snapshot_saving
 * @property {total_projected_savings} total_projected_savings
 * @property {number} saving_till_date
 */

/**
 * @typedef total_savings
 * @property {Number} count
 * @property {Number} cost
 */

/**
 * @typedef idle_resources_saving
 * @property {Number} count
 * @property {Number} cost
 */

/**
 * @typedef right_sizing_saving
 * @property {Number} count
 * @property {Number} cost
 */
/**
 * @typedef snapshot_saving
 * @property {Number} count
 * @property {Number} cost
 */

/**
 * @typedef total_projected_savings
 * @property {Number} count
 * @property {Number} cost
 */

/**
 * @typedef GetSavingFilters
 * @property {string[]} accounts
 * @property {string[]} regions
 * @property {string[]} tags
 */
/**
 * @typedef GetType
 * @property {string} month
 * @property {string} year
 */

/**
 * @typedef AllBodyStatisticsRes
 * @property {AllHeaderStatics} statistics
 * @property {GetSavingFilters} filters
 */

/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'azure' | 'gcp' | 'all-cloud' } service
 * @property {string} fromDate
 * @property {string} toDate
 * @property {GetType} type
 */

/**
 * @param {GetStatisticsReq} req
 * @returns {Promise<AllBodyStatisticsRes>}
 */

export const getRecHEaderstaticsAzure = (token) => {
  return async ({ service, toDate, fromDate, filters }, signal) => {
    const body = {
      accounts: filters?.accounts || [],
      regions: filters?.regions || [],
      tags: filters?.tags || [],
    };

    if (service === "azure") {
      body.rg = filters?.rg || [];
    }
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/savings/total-stats?from=${fromDate}&to=${toDate}`,
      {
        method: service === "all-cloud" ? "GET" : "POST",
        body: service !== "all-cloud" ? JSON.stringify(body) : undefined,
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );

    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }
      throw new Error((await owners.json()).message);
    }

    const data = await owners.json();

    if (
      (!data?.data?.total_savings?.cost ||
        data?.data?.total_savings?.cost === 0) &&
      (!data?.data?.idle_resources_saving?.cost ||
        data?.data?.idle_resources_saving?.cost === 0) &&
      (!data?.data?.right_sizing_saving?.cost ||
        data?.data?.right_sizing_saving?.cost === 0) &&
      (!data?.data?.snapshot_saving?.cost ||
        data?.data?.snapshot_saving?.cost === 0) &&
      (!data?.data?.total_projected_savings?.cost ||
        data?.data?.total_projected_savings?.cost === 0) &&
      (!data?.data?.saving_till_date || data?.data?.saving_till_date === 0)
    ) {
      throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    }
    return data;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useStatisticsHeaderAzure = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getRecHEaderstaticsAzure", req, ...deps],
    queryFn: () => getRecHEaderstaticsAzure(appContext.idToken)(req),
  });
  return query;
};

// --------------------------------header for previous data---------------------------------------------------
export const getRecHEaderstaticsAzurePost = (token) => {
  return async ({ service, toDate, fromDate, filters, type }, signal) => {
    const body = {
      accounts: filters?.accounts || [],
      regions: filters?.regions || [],
      tags: filters?.tags || [],
    };

    if (service === "azure") {
      body.rg = filters?.rg || [];
    }
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/savings/total-stats-past?from=${fromDate}&to=${toDate}&type=${type}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useStatisticsHeaderPost = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getRecHEaderstaticsAzurePost", req, ...deps],
    queryFn: () => getRecHEaderstaticsAzurePost(appContext.idToken)(req),
  });
  return query;
};
