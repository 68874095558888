import React, { useContext, useEffect } from "react";
import FilterSection from "./filter_section/filterSection";
import copy from "@/assets/img/copy-06 1.svg";
import clsx from "clsx";
import { useState, useMemo } from "react";
import { Dropdown, Button, DropdownButton } from "react-bootstrap";
import Pagination from "@/components/pagination/pagination.component";
import { useSnapTableStatistics } from "@/services/snapshots/getTableStatics.repo";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAcceptDismissSnapshot } from "@/services/snapshots/addAcceptDismissSnapshot.repo";
import styles from "@/page-components/snapshot/snapshots.module.css";
import TableErrorState from "@/components/States/TableErrorState";
import ToastComponent from "@/page-components/budgets/components/toast";
import AcceptModalComponent from "./state_change/AcceptModal2";
import DiscardedModal from "./state_change/discardModal";
import { SnapshotContext } from "../snap.contex";
import ArchiveModal from "./state_change/archiveModal";
import { useUpdateAcceptRecommendation } from "@/services/snapshots/addAcceptFields.repo";
import { useGetHistory } from "@/services/snapshots/getAcceptDismissHistory.repo";
import { useDiscardedTableStats } from "@/services/snapshots/getDiscardedTable.repo";
import DiscardedTable from "./discardedTable";
import sorting from "@/assets/img/sorting.svg";
import retaindisable from "@/assets/img/reataindisable.svg";
import Tooltip from "@/components/tooltip/tooltip";
import DoneIcon from "@/assets/img/doneStateChange.svg";
import jiraHover from "@/assets/img/jiraHover.svg";
import Viajira from "@/assets/img/viajira.svg";
import retain from "@/assets/img/retainSnap.svg";
import { Offcanvas } from "react-bootstrap";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import download from "@/assets/img/download.svg";
import menuicon from "@/assets/img/menu.svg";
import { UseSnapshotsDownload } from "@/services/snapshots/getSnapshotDownload.repo";
import DropdownComponent from "@/common/DropdownComponent";
import downlaodVM from "@/assets/img/downloadVM.svg";
import disablejira from "@/assets/img/jiradisable.svg";
import { OverlayTrigger, Tooltip as ReactTooltip } from "react-bootstrap";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import CurrencyFormatter from "@/lib/value-converison";
import AcceptViaJira from "@/components/state-change/accept-via-jira";
import ViewAcceptData from "@/components/state-change/view-accept-data";
import RetainModal from "@/components/state-change/retain-modal";
import { useUpdateAcceptRecommendationJira } from "@/services/snapshots/addjiraAccept.repo";
import { useStatisticsjira } from "@/services/snapshots/getjiraTablepopup.repo";

const BodySection = ({
  showFilterSection,
  setSelectedOptionStatus,
  service,
  name,
  headerData,
  refreshHeaders,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [resourceId, setResourceId] = useState(null);
  const [checkedHistory, setCheckedHistory] = useState(null);
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const [copiedResourceId, setCopiedResourceId] = useState(null);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState();
  const [enableAcceptModal, setEnableAcceptModal] = useState(false);
  const [enableDiscardModal, setEnableDiscardModal] = useState(false);
  const [enableArchiveModal, setEnableArchiveModal] = useState(false);
  const [savingsId, setSavingsId] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [orderBy, setOrderBy] = useState("accountName");
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const context = useContext(SnapshotContext);
  const [hideFilterSection, setHideFilterSection] = useState(false);
  const [width, setwidth] = useState("");
  const [ShowModal, setShowModal] = useState(false);
  const [ShowAcceptModal, setShowAcceptModal] = useState(false);
  const [actionType, setactionType] = useState("");
  const navigate = useNavigate();

  const selectedResourcesModified = context?.selectedItems?.map((item) => ({
    snapshotId: item.snapshotId,
    snapshotName: item.snapshotName,
    accountId: item.account,
    accountName: item.accountName,
    snapshotCost: item.snapshotcost,
  }));

  useEffect(() => {
    context?.setSelectedItems([]);
  }, [context?.selectedResourceType]);
  const { data: addAcceptDismissSnapshotData } = useAcceptDismissSnapshot();
  const {
    data: updateData,
    mutate: acceptMutate,
    isError: isAcceptDismissError,
    error: acceptDismissError,
  } = useUpdateAcceptRecommendation();

  const {
    data: updateDatajira,
    mutate: acceptMutatejira,
    isSuccess: jiraAcceptSuccess,
    isError: isAcceptDismissErrorjira,
    error: acceptDismissErrorjira,
    isPending: jiraPendingReq,
  } = useUpdateAcceptRecommendationJira();

  const queryjiraPopup = useMemo(() => {
    return {
      service: service,
      id: context?.selectedJiraticket,
    };
  }, [context?.selectedJiraticket]);

  const { data: jiraData, isLoading: jiraModalLoading } =
    useStatisticsjira(queryjiraPopup);

  const queryParam = useMemo(() => {
    return {
      service,
      id: savingsId,
    };
  }, [service, savingsId]);

  const { data: historyData } = useGetHistory(queryParam);
  const order = searchParams.get("desc") || "asc";
  const handleShowJiraModal = (item) => {
    context?.setShowJiraModal(true);
    context?.setSelectedItems([item]);
  };
  const handleCloseJiraModal = () => {
    context?.setShowJiraModal(false);
    context?.setSelectedItems([]);
  };

  const handleShowacceptmodal = (resource_id) => {
    setResourceId(resource_id);
    context?.setAcceptViaJira(true);
  };
  const handleClosecceptmodal = () => {
    context?.setAcceptViaJira(false);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };
  const handleShowRetainModal = (item) => {
    context?.setShowRetainModal(true);
    context?.setSelectedItems([item]);
  };
  const handleCloseRetainModal = () => {
    context?.setShowRetainModal(false);
    context?.setSelectedItems([]);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };

  const query = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    const tags = (context?.selectedSnapshotTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );

    const filters = {
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectedRegions?.length
        ? context.selectedRegions.map((e) => e.id)
        : undefined,
      diskIds: context?.selectedDisk?.length
        ? context.selectedDisk.map((e) => e.id)
        : undefined,
      snapshotAge: context?.selectedSnapshotAge?.length
        ? context.selectedSnapshotAge.map((e) => e.id)
        : undefined,
      tags: tags.length ? tags : undefined,
      minAge: context?.value?.length ? context?.value[0] : undefined,
      maxAge: context?.value?.length ? context?.value[1] : undefined,
      resourceGroupNames: context?.selectedResourceGroup?.length
        ? context?.selectedResourceGroup.map((e) => e.id)
        : undefined,
    };

    if (context?.showUntaggedOnly) {
      filters.filterUntagged = context.showUntaggedOnly;
    }

    if (context?.showOrphanedOnly) {
      filters.filterOrphaned = context.showOrphanedOnly;
    }
    if (context?.legallyHeldSnapshot) {
      filters.filterLegallyHeld = context?.legallyHeldSnapshot;
    }

    return {
      service,
      page: parsedPage || 1,
      limit: parsedItemsPerPage || 10,
      order: order,
      orderBy: orderBy || "",
      filters,
      status: context?.selectedResourceType,
    };
  }, [
    page,
    itemsPerPage,
    context?.selectedSnapshotTags,
    context.selectedAccounts,
    context.selectedRegions,
    context.selectedDisk,
    context.selectedSnapshotAge,
    context?.value,
    context?.selectedResourceGroup,
    context.showUntaggedOnly,
    context.showOrphanedOnly,
    context?.legallyHeldSnapshot,
    service,
    order,
    orderBy,
    context?.selectedResourceType,
  ]);

  const {
    data,
    isLoading,
    error,
    refetch: refetchActiveTable,
  } = useSnapTableStatistics(query, [updateDatajira]);

  useEffect(() => {
    if (updateDatajira && error?.message === "API_NO_DATA_FOUND_ERROR") {
      setSearchParams((prevParams) => {
        const updatedParams = new URLSearchParams(prevParams);
        updatedParams.set("page", "1");
        return updatedParams;
      });

      refetchActiveTable();
    }
  }, [
    updateDatajira,
    data?.snapshots?.length,
    refetchActiveTable,
    error?.message,
  ]);

  useEffect(() => {
    if (updateDatajira && jiraAcceptSuccess) {
      refetchActiveTable();
      refreshHeaders();
      // context?.setShowRetainModal(false);
      // context?.setShowJiraModal(false);
    }
  }, [
    jiraAcceptSuccess,
    refetchActiveTable,
    refreshHeaders,
    updateData,
    updateDatajira,
  ]);
  // onrefresh selected item -----------
  // useEffect(() => {
  //   context?.setSelectedItems([]);
  // }, [selectedResourceType]);

  useEffect(() => {
    if (
      error &&
      (error.message === API_NO_DATA_FOUND_ERROR ||
        error.message === API_NOT_FOUND_ERROR ||
        error.message === API_LOADING_ERROR)
    ) {
      setHideFilterSection(true);
    } else {
      setHideFilterSection(false);
    }
  }, [error]);

  // const queryDiscard = useMemo(() => {
  //   const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
  //   const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
  //     ? parseInt(itemsPerPage)
  //     : 10;
  //   return {
  //     service,
  //     page: parsedPage || 1,
  //     limit: parsedItemsPerPage || 10,
  //   };
  // }, [itemsPerPage, page, service]);

  // const {
  //   data: discardedData,
  //   isError: discardError,
  //   error: discarListError,
  //   isLoading: discardLoading,
  //   refetch: discardRefetch,
  // } = useDiscardedTableStats(queryDiscard);

  const handleCopyClick = (snapshotId) => {
    const textarea = document.createElement("textarea");
    textarea.value = snapshotId;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopiedResourceId(snapshotId);
    setCopiedResourceId(snapshotId);
    setShowCopyMessage(true);

    setTimeout(() => {
      setShowCopyMessage(false);
    }, 5000);
  };

  const handleOrderByClick = (columnName) => {
    setOrderBy(columnName);
    const sortingBy = order === "desc" ? "asc" : "desc";

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("orderBy", orderBy);
    updatedSearchParams.set("desc", sortingBy);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    if (addAcceptDismissSnapshotData) {
      refreshHeaders();
    }
  }, [addAcceptDismissSnapshotData, refreshHeaders]);

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  /**MODALS FOR ACCEPT, DISMISS AND ARCHIVE */

  const handleModal = (resourceId, snapshotId, history) => {
    setEnableAcceptModal(true);
    setResourceId(resourceId);
    setSavingsId(snapshotId);
    setCheckedHistory(history);
  };

  const disableModal = () => {
    setEnableAcceptModal(false);
    setResourceId(undefined);
    setSavingsId(undefined);
    setCheckedHistory(undefined);
  };

  const handleDiscardModal = (resourceId, snapshotId, history) => {
    setEnableDiscardModal(true);
    setResourceId(resourceId);
    setSavingsId(snapshotId);
    setCheckedHistory(history);
  };

  const handleDiscardedModalClose = () => {
    setEnableDiscardModal(false);
    setResourceId(undefined);
    setSavingsId(undefined);
    setCheckedHistory(undefined);
  };

  const handleArchiveModal = (resourceId, snapshotId, history) => {
    setEnableArchiveModal(true);
    setResourceId(resourceId);
    setSavingsId(snapshotId);
    setCheckedHistory(history);
  };

  const handleArchiveModalClose = () => {
    setEnableArchiveModal(false);
    setResourceId(undefined);
    setSavingsId(undefined);
    setCheckedHistory(undefined);
  };

  // useEffect(() => {
  //   if (updateDatajira) {
  //     refetchActiveTable();
  //     refreshHeaders();
  //     discardRefetch();
  //     setEnableArchiveModal(false);
  //     setEnableDiscardModal(false);
  //     setEnableAcceptModal(false);
  //   }
  // }, [updateDatajira, refetchActiveTable, refreshHeaders]);

  useEffect(() => {
    if (acceptDismissError) {
      setEnableAcceptModal(false);
      setEnableArchiveModal(false);
      setEnableDiscardModal(false);
      setMessage({
        message: acceptDismissError?.message,
        type: "Anamaly",
      });
      setToast(true);
    }
  }, [acceptDismissError, setMessage, isAcceptDismissError]);

  useEffect(() => {
    if (acceptDismissErrorjira) {
      context?.setShowRetainModal(false);
      context?.setShowJiraModal(false);
      context?.setSelectedItems([]);
      setMessage({
        message: acceptDismissErrorjira?.message,
        type: "Anamaly",
      });
      setToast(true);
    }
  }, [acceptDismissErrorjira, setMessage, isAcceptDismissErrorjira]);

  useEffect(() => {
    if (jiraAcceptSuccess) {
      context?.setShowRetainModal(false);
      context?.setShowJiraModal(false);
      context?.setSelectedItems([]);
      setMessage({
        message: updateDatajira?.message,
        type: "success",
      });
      setToast(true);
      setSearchParams((prevParams) => {
        const updatedParams = new URLSearchParams(prevParams);
        updatedParams.set("page", "1");
        return updatedParams;
      });
    }
  }, [jiraAcceptSuccess, setMessage, updateDatajira?.message]);

  // useEffect(() => {
  //   if (ActiveDismissError) {
  //     setMessage({
  //       message: ActiveDismissError?.message,
  //       type: "Anamaly",
  //     });
  //     console.log("myerror---", ActiveDismissError?.message);
  //     setToast(true);
  //   }
  // }, [ActiveDismissError, checking, setMessage]);
  // console.log("resource id ", resourceId);

  const invalidStatus = (status) => {
    switch (status) {
      case "done":
      case "archived":
      case "open":
      case "inprogress":
      case "accepted":
      case "discarded":
        return false;
      default:
        return true;
    }
  };

  useEffect(() => {
    if (hideFilterSection === true) {
      setwidth("100");
    }
    if (hideFilterSection === false) {
      setwidth("82");
    }
  }, [hideFilterSection]);

  const downloadQuery = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    const tags = (context?.selectedSnapshotTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    const filters = {
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectedRegions?.length
        ? context.selectedRegions.map((e) => e.id)
        : undefined,
      diskIds: context?.selectedDisk?.length
        ? context.selectedDisk.map((e) => e.id)
        : undefined,
      snapshotAge: context?.selectedSnapshotAge?.length
        ? context.selectedSnapshotAge.map((e) => e.id)
        : undefined,
      tags: tags.length ? tags : undefined,
      minAge: context?.value?.length ? context?.value[0] : undefined,
      maxAge: context?.value?.length ? context?.value[1] : undefined,
      resourceGroupNames: context?.selectedResourceGroup?.length
        ? context?.selectedResourceGroup.map((e) => e.id)
        : undefined,
    };

    if (context?.showUntaggedOnly) {
      filters.filterUntagged = context.showUntaggedOnly;
    }

    if (context?.showOrphanedOnly) {
      filters.filterOrphaned = context.showOrphanedOnly;
    }
    if (context?.legallyHeldSnapshot) {
      filters.filterLegallyHeld = context?.legallyHeldSnapshot;
    }
    return {
      service,
      tableName: "download",
      page: parsedPage || 1,
      limit: parsedItemsPerPage || 10,
      order: order,
      orderBy: orderBy || "",
      filters,
      status: context?.selectedResourceType,
    };
  }, [
    page,
    itemsPerPage,
    context?.selectedSnapshotTags,
    context.selectedAccounts,
    context.selectedRegions,
    context.selectedDisk,
    context.selectedSnapshotAge,
    context?.value,
    context?.selectedResourceGroup,
    context.showUntaggedOnly,
    context.showOrphanedOnly,
    context?.legallyHeldSnapshot,
    service,
    order,
    orderBy,
    context?.selectedResourceType,
  ]);
  const { mutate: downloadMutate, isPending: downLaodLoading } =
    UseSnapshotsDownload(downloadQuery);
  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };
  const handleResourceTypeSelect = (selectedType) => {
    let status;

    switch (selectedType) {
      case "Active":
        status = "Active";
        break;
      case "In Progress":
        status = "In Progress";
        break;
      default:
        status = selectedType.toLowerCase();
    }

    context?.setSelectedResourceType(selectedType);

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("status", status);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };
  const handleCheckboxChange = (isChecked, item) => {
    context?.setSelectedItems((prev) => {
      if (isChecked) {
        if (!prev.some((entry) => entry.resource_id === item.resource_id)) {
          if (prev.length >= 20) {
            alert("You cannot select more than 20 items.");
            return prev;
          }
          return [...prev, item];
        }
        return prev;
      } else {
        return prev.filter((entry) => entry.resource_id !== item.resource_id);
      }
    });
  };

  useEffect(() => {
    if (downLaodLoading) {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }
    return () => {
      document.body.style.cursor = "default";
    };
  }, [downLaodLoading]);
  const handleSelectAllChange = (isChecked) => {
    const currentPageItems = data.snapshots;
  
    context?.setSelectedItems((prev) => {
      if (isChecked) {
        const prevIds = new Set(prev.map((item) => item.resource_id));
        const newItems = currentPageItems.filter(
          (item) => !prevIds.has(item.resource_id)
        );
        const mergedItems = [...prev, ...newItems];
  
        if (mergedItems.length > 20) {
          alert("You cannot select more than 20 items.");
          return prev;
        }
        return mergedItems;
      } else {
        return prev.filter(
          (item) =>
            !currentPageItems.some(
              (currentPageItem) =>
                currentPageItem.resource_id === item.resource_id
            )
        );
      }
    });
  };

  // const handleShowmodal = () => {
  //   setShowModal(true);
  // };
  // const handleShowacceptmodal = () => {
  //   setShowAcceptModal(true);
  // };

  return (
    <>
      {toast && (
        <ToastComponent
          message={message}
          toast={toast}
          setToast={setToast}
          timeout={5000}
        />
      )}

      {context.eventkey == "Active" && (
        <>
          <div className={styles.body_section} style={{ paddingRight: "40px" }}>
            <div
              className={styles.Chart_table_section}
              style={{ width: `${width}%` }}
            >
              <>
                <div className={styles.snap_Heading_section}>
                  <div className={styles.justified_space}>
                    <span className={styles.heading_text_body}>
                      {context?.legallyHeldSnapshot
                        ? `Legally Held Snapshots Details (${
                            data?.total || 0
                          } Snapshots)`
                        : `Snapshots Details (${data?.total || 0} Snapshots)`}
                    </span>
                  </div>
                </div>
                <div className={styles.menuStatusContainer}>
                  <div
                    className={styles.statsSelectedPart}
                    style={{ display: "flex", gap: "8px" }}
                  >
                    <div className={styles.res_type}>
                      <div className={styles.res_type_text}>Status</div>
                      <div>
                        <DropdownButton
                          id={styles.dropdown_item_button}
                          title={
                            context?.selectedResourceType &&
                            context?.selectedResourceType === "InProgress"
                              ? "In Progress"
                              : context?.selectedResourceType
                          }
                          style={{}}
                        >
                          <Dropdown.Item
                            className={styles.dropdown_item}
                            as="button"
                            onClick={() => {
                              handleResourceTypeSelect("Active");
                              context?.setEventkey("Active");
                              // setSelectedOptionStatus("Active")
                            }}
                          >
                            Active
                          </Dropdown.Item>
                          <Dropdown.Item
                            className={styles.dropdown_item}
                            as="button"
                            onClick={() => {
                              handleResourceTypeSelect("In Progress");
                              context?.setEventkey("Active");
                              // setSelectedOptionStatus("In Progress")
                            }}
                          >
                            In Progress
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </div>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#212529",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Selected
                      <span style={{ marginLeft: "5px", color: "#797B80" }}>
                        ({(context?.selectedItems || []).length || 0})
                      </span>
                    </span>
                  </div>

                  <div
                    className={styles.downloadPart}
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <div
                      className={styles.res_type_text}
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color:
                          context?.selectedItems.length > 0
                            ? "#056EE5"
                            : "#ADB5BD",
                        cursor:
                          context?.selectedItems.length > 0 ? "pointer" : "",
                      }}
                      onClick={() => {
                        context?.selectedItems.length > 0 &&
                          context?.setShowJiraModal(true);
                        // setactionType("jira");
                      }}
                    >
                      <img
                        src={
                          context?.selectedItems.length > 0
                            ? Viajira
                            : disablejira
                        }
                        alt=""
                        style={{ marginRight: "-6px", color: "#ADB5BD" }}
                      />
                      Accept via Jira
                    </div>
                    <div
                      className={styles.res_type_text}
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        cursor:
                          context?.selectedItems.length > 0 ? "pointer" : "",
                        color:
                          context?.selectedItems.length > 0 ? "" : "#ADB5BD",
                      }}
                      onClick={() => {
                        context?.selectedItems.length > 0 &&
                          context?.setShowRetainModal(true);
                        // setactionType("accept")
                      }}
                    >
                      <img
                        src={
                          context?.selectedItems.length > 0
                            ? retain
                            : retaindisable
                        }
                        alt=""
                      />
                      Retain
                    </div>
                    <div className={styles.menu_icon_css1}>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <ReactTooltip id="tooltip">
                            Download as CSV
                          </ReactTooltip>
                        }
                      >
                        <span
                          style={{ cursor: downLaodLoading?"wait":"pointer" }}
                          className={styles.downloadButton}
                          onClick={handleDownloadClick}
                        >
                          <img src={downlaodVM} alt="" />
                        </span>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>

                {isLoading && <TableErrorState />}
                {error && (
                  <ScenariosErrorState
                    error={error.message}
                    messageConfig={{
                      [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                        message: "No Data Available for Selected Filters",
                        additionalMessage:
                          "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
                      },
                      [API_NO_DATA_FOUND_ERROR]: {
                        message: "No Data Available",
                        additionalMessage:
                          "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                      },
                      [API_LOADING_ERROR]: {
                        message: "Oops!",
                        additionalMessage:
                          "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                      },
                      [API_NOT_FOUND_ERROR]: {
                        message: "404 Error",
                        additionalMessage:
                          "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                      },
                    }}
                  />
                )}
                {data &&
                  data.snapshots &&
                  data.snapshots.length > 0 &&
                  !error && (
                    <>
                      <div className={styles.snap_table_section}>
                        <div
                          className={styles.table_container}
                          style={{ overflowX: "scroll" }}
                        >
                          <table
                            className="table "
                            style={{ margin: "0px", width: "100%" }}
                          >
                            <thead>
                              <tr
                                style={{
                                  background: "#F6F6F6",
                                  borderBottom: "1px solid #F6F6F6",
                                }}
                              >
                                <th className={styles.snap_th_class}>
                                  {context?.selectedResourceType ===
                                  "Active" ? (
                                    <input
                                      type="checkbox"
                                      style={{
                                        cursor: "pointer",
                                        width: "16px",
                                        height: "16px",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      onChange={(e) =>
                                        handleSelectAllChange(e.target.checked)
                                      }
                                      checked={data.snapshots.every((item) =>
                                        context?.selectedItems.some(
                                          (entry) =>
                                            entry.resource_id ===
                                            item.resource_id
                                        )
                                      )}
                                      name=""
                                    />
                                  ) : (
                                    <th className={styles.snap_th_class}></th>
                                  )}
                                </th>

                                <th className={styles.snap_th_class}>
                                  {name}
                                  <img
                                    src={sorting}
                                    alt="sorting"
                                    className={styles.sorting_icon}
                                    onClick={() =>
                                      handleOrderByClick("snapshotName")
                                    }
                                  ></img>
                                </th>
                                <th className={styles.snap_th_class}>
                                  Snapshot Name
                                  <img
                                    src={sorting}
                                    alt="sorting"
                                    className={styles.sorting_icon}
                                    onClick={() =>
                                      handleOrderByClick("snapshotcost")
                                    }
                                  ></img>
                                </th>
                                {service === "azure" && (
                                  <th className={styles.snap_th_class}>
                                    Resource Group
                                    <img
                                      src={sorting}
                                      alt="sorting"
                                      className={styles.sorting_icon}
                                      onClick={() =>
                                        handleOrderByClick("resourceGroupName")
                                      }
                                    ></img>
                                  </th>
                                )}
                                <th className={styles.snap_th_class}>
                                  Snapshot ID
                                  <img
                                    src={sorting}
                                    alt="sorting"
                                    className={styles.sorting_icon}
                                    onClick={() =>
                                      handleOrderByClick("snapshotId")
                                    }
                                  ></img>
                                </th>
                                <th className={styles.snap_th_class}>
                                  Region
                                  <img
                                    src={sorting}
                                    alt="sorting"
                                    className={styles.sorting_icon}
                                    onClick={() => handleOrderByClick("region")}
                                  ></img>
                                </th>

                                <th
                                  className={clsx(
                                    styles.snap_th_class,
                                    styles.snap_th_class_center
                                  )}
                                >
                                  Snapshot Age
                                  <img
                                    src={sorting}
                                    alt="sorting"
                                    className={styles.sorting_icon}
                                    onClick={() =>
                                      handleOrderByClick("snapshotAge")
                                    }
                                  ></img>
                                </th>

                                <th className={styles.snap_th_class}>
                                  Volume ID
                                  <img
                                    src={sorting}
                                    alt="sorting"
                                    className={styles.sorting_icon}
                                    onClick={() => handleOrderByClick("diskId")}
                                  ></img>
                                </th>
                                <th className={styles.snap_th_class}>
                                  Disk Size
                                  <img
                                    src={sorting}
                                    alt="sorting"
                                    className={styles.sorting_icon}
                                    onClick={() =>
                                      handleOrderByClick("diskSize")
                                    }
                                  ></img>
                                </th>
                                <th className={styles.snap_th_class}>
                                  Incremental
                                </th>

                                <th
                                  className={clsx(
                                    styles.snap_th_class,
                                    styles.snap_th_class_center
                                  )}
                                >
                                  Storage Class
                                </th>
                              </tr>
                            </thead>
                            <tbody
                              style={{
                                borderRight: "white",
                                borderLeft: "white",
                              }}
                            >
                              {(data?.snapshots || []).map((data, index) => (
                                <tr
                                  className={clsx(
                                    {
                                      [styles.trhovered]:
                                        index === hoveredRowIndex,
                                      [styles.trBAckground]:
                                        context?.selectedItems.some(
                                          (entry) =>
                                            entry.snapshotId ===
                                            data?.snapshotId
                                        ),
                                    },
                                    styles.for_copy_hover
                                  )}
                                  key={index}
                                  onMouseEnter={() => handleMouseEnter(index)}
                                  onMouseLeave={handleMouseLeave}
                                >
                                  <td
                                    className={clsx(
                                      styles.snap_table_data__Class,
                                      "elipese"
                                    )}
                                  >
                                    {context?.selectedResourceType ===
                                    "Active" ? (
                                      <input
                                        type="checkbox"
                                        style={{
                                          cursor: "pointer",
                                          width: "16px",
                                          height: "23px",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            e.target.checked,
                                            data
                                          )
                                        }
                                        checked={context?.selectedItems.some(
                                          (entry) =>
                                            entry.resource_id ===
                                            data.resource_id
                                        )}
                                      />
                                    ) : (
                                      <img
                                        style={{
                                          cursor: "pointer",
                                          width: "16px",
                                          height: "23px",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        src={jiraHover}
                                        alt=""
                                        onClick={() => {
                                          context.setselectedJiraticket(
                                            data?.ticket_id
                                          );
                                          handleShowacceptmodal(
                                            data?.resource_id
                                          );
                                        }}
                                      />
                                    )}
                                  </td>
                                  <td
                                    className={clsx(
                                      styles.snap_table_data__Class,
                                      "elipese"
                                    )}
                                    title={data?.accountName}
                                  >
                                    {data?.accountName || "--"}
                                  </td>
                                  <td
                                    className={clsx(
                                      styles.snap_table_data__Class,
                                      "elipese"
                                    )}
                                    title={data?.snapshotName}
                                  >
                                    {data?.snapshotName || "--"}
                                  </td>
                                  {service === "azure" && (
                                    <td
                                      className={clsx(
                                        styles.snap_table_data__Class,
                                        "elipese"
                                      )}
                                      title={data?.resourceGroupName}
                                    >
                                      {data?.resourceGroupName || "--"}
                                    </td>
                                  )}
                                  <td
                                    className={clsx(
                                      styles.snap_table_data__Class
                                    )}
                                    title={data?.snapshotId}
                                    style={{ marginRight: "8px" }}
                                  >
                                    <span
                                      style={{
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        maxWidth: "150px",
                                        display: "inline-block",
                                        lineHeight: "1.2",
                                      }}
                                    >
                                      {data?.snapshotId || "--"}
                                    </span>
                                    <span
                                      className={`${styles.snap_copy_icon} ${
                                        copiedResourceId === data?.snapshotId
                                          ? "visible"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleCopyClick(data?.snapshotId)
                                      }
                                    >
                                      <img src={copy} alt="" />
                                    </span>
                                  </td>
                                  <td
                                    className={clsx(
                                      styles.snap_table_data__Class,
                                      "elipese"
                                    )}
                                  >
                                    {data?.region || "--"}
                                  </td>
                                  
                                  <td
                                    className={clsx(
                                      styles.snap_table_data__Class,
                                      styles.snapshot_col_center
                                    )}
                                  >
                                    {data?.snapshotAge || "--"}
                                  </td>
                                  <td
                                    className={clsx(
                                      styles.snap_table_data__Class,
                                      styles.truncate,
                                      "elipese"
                                    )}
                                    title={data?.diskId}
                                  >
                                    {data?.diskId || "--"}
                                  </td>
                                  <td className={styles.snap_table_data__Class}>
                                    {data?.diskSize || 0}-GB
                                  </td>
                                  <td
                                    className={clsx(
                                      styles.snap_table_data__Class,
                                      "elipese"
                                    )}
                                  >
                                    {data?.incremental === 0 ? "False" : "True"}
                                  </td>
                                  <td
                                    className={clsx(
                                      styles.snap_table_data__Class,
                                      styles.snapshot_col_center,
                                      styles.snapshot_col_weight,
                                      // data?.snapshotClass === "ARCHIVE" &&
                                      //   styles.snapshot_archived,
                                      data?.snapshotClass !== "Unarchive" &&
                                        data?.snapshotClass !== null &&
                                        styles.snapshot_unarchived
                                    )}
                                    style={{
                                      color:
                                        data?.snapshotClass === "ARCHIVE"
                                          ? "#ed9615"
                                          : "#056ee5",
                                    }}
                                  >
                                    {data?.snapshotClass
                                      ? String(
                                          data.snapshotClass
                                        ).toUpperCase() === "ARCHIVE"
                                        ? "Archive"
                                        : "Standard"
                                      : "NA"}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className={styles.container_secTable}>
                          <div
                            className={styles.table_container}
                            style={{ width: "100%" }}
                          >
                            <table
                              className="table "
                              style={{ margin: "0px", width: "100%" }}
                            >
                              <thead>
                                <tr
                                  style={{
                                    background: "#F6F6F6",
                                    borderBottom: "1px solid #F6F6F6",
                                  }}
                                >
                                  <th
                                    className={clsx(
                                      styles.snap_th_class,
                                      styles.snap_th_class_center
                                    )}
                                  >
                                    Cost Paid
                                  </th>
                                  <th
                                    className={clsx(
                                      styles.snapshot_col_end,
                                      styles.snap_th_class
                                    )}
                                  >
                                    Estimated Monthly Savings
                                    <img
                                      src={sorting}
                                      alt="sorting"
                                      className={styles.sorting_icon}
                                      onClick={() =>
                                        handleOrderByClick("snapshotcost")
                                      }
                                    ></img>
                                  </th>
                                  {!context?.legallyHeldSnapshot && (
                                    <>
                                      <th
                                        className={clsx(
                                          styles.snapshot_col_end,
                                          styles.snap_th_class
                                        )}
                                        style={{ textAlign: "left" }}
                                      >
                                        Status
                                      </th>
                                      {context?.selectedResourceType ===
                                        "In Progress" && (
                                        <th
                                          className={clsx(
                                            styles.snapshot_col_end,
                                            styles.snap_th_class
                                          )}
                                        >
                                          Jira Status
                                        </th>
                                      )}
                                      <th className={styles.snap_th_class}></th>
                                    </>
                                  )}
                                </tr>
                              </thead>
                              <tbody
                                className={styles.snap_table_data__Class}
                                style={{
                                  borderRight: "white",
                                  borderLeft: "white",
                                }}
                              >
                                {(data?.snapshots || []).map((data, index) => (
                                  <tr
                                    className={clsx({
                                      [styles.trhovered]:
                                        index === hoveredRowIndex,
                                      [styles.trBAckground]:
                                        context?.selectedItems.some(
                                          (entry) =>
                                            entry.snapshotId ===
                                            data?.snapshotId
                                        ),
                                    })}
                                    key={index}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                  >
                                    <td
                                      className={clsx(
                                        styles.snap_table_data__Class,
                                        styles.snapshot_col_weight,
                                        styles.snapshot_col_end
                                      )}
                                    >
                                      <ToolTipComponent
                                        data={data?.snapshotcost}
                                      >
                                        {data?.snapshotcost === null && "--"}
                                        {data?.snapshotcost != null &&
                                          data?.snapshotcost < 0.1 &&
                                          "< $0.1"}
                                        {data?.snapshotcost > 0.1 && (
                                          <CurrencyFormatter
                                            value={data?.snapshotcost}
                                            hideDollar={false}
                                          />
                                        )}
                                      </ToolTipComponent>
                                      {/* <Tooltip
                                    // tooltipContent={
                                    //   data?.snapshotcost != null
                                    //     ? "$" +
                                    //       (data?.snapshotcost || 0).toFixed(8)
                                    //     : "$0"
                                    // }
                                    overlayProps={{ placement: "top" }}
                                  >
                                    {(show, setShow) => (
                                      <Button
                                        onMouseLeave={() => setShow(false)}
                                        onMouseEnter={() => setShow(true)}
                                        variant="link"
                                        className={clsx(
                                          styles.snap_table_data__Class,
                                          styles.snapshot_col_weight,
                                          styles.snapshot_col_end
                                        )}
                                      >
                                        {data?.snapshotcost === null && "--"}
                                        {data?.snapshotcost != null &&
                                          data?.snapshotcost < 0.1 &&
                                          "< $0.1"}
                                        {data?.snapshotcost > 0.1 &&
                                          "$" +
                                            (data?.snapshotcost || 0).toFixed(
                                              2
                                            )}
                                      </Button>
                                    )}
                                  </Tooltip> */}
                                    </td>
                                    <td
                                      className={clsx(
                                        styles.snap_table_data__Class,
                                        styles.snapshot_col_weight,
                                        styles.snapshot_col_end
                                      )}
                                    >
                                      <ToolTipComponent
                                        data={data?.remaining_saving}
                                      >
                                        {data?.remaining_saving
                                          ? data?.remaining_saving === null &&
                                            "--"
                                          : "--"}
                                        {data?.remaining_saving != null &&
                                          data?.remaining_saving < 0.1 &&
                                          "< $0.1"}
                                        {data?.remaining_saving > 0.1 && (
                                          <CurrencyFormatter
                                            value={data?.remaining_saving}
                                            hideDollar={false}
                                          />
                                        )}
                                      </ToolTipComponent>
                                    </td>
                                    {!context?.legallyHeldSnapshot && (
                                      <>
                                        <td
                                          className={clsx(
                                            styles.snap_table_data__Class,
                                            styles.snapshot_col_weight,
                                            styles.snapshot_col_end
                                          )}
                                          style={{ textAlign: "left" }}
                                        >
                                          {data?.isLegallyHeld !== "true" &&
                                            Boolean(data?.isLegallyHeld) ===
                                              false && (
                                              <>
                                                {data?.status == "active" && (
                                                  <span
                                                    className={styles.badgeName}
                                                  >
                                                    {" "}
                                                    Active
                                                  </span>
                                                )}
                                                {data?.status == "open" && (
                                                  <span
                                                    className={styles.badgeName}
                                                  >
                                                    {" "}
                                                    Open
                                                  </span>
                                                )}
                                                {data?.status == "accepted" && (
                                                  <span
                                                    className={
                                                      styles.badgeNamePending
                                                    }
                                                  >
                                                    Pending Approval
                                                  </span>
                                                )}
                                                {data?.status ==
                                                  "in_progress" && (
                                                  <span
                                                    className={
                                                      styles.badgeNameProgress
                                                    }
                                                  >
                                                    In Progress
                                                  </span>
                                                )}
                                                {data?.status == "done" && (
                                                  <span
                                                    className={
                                                      styles.badgeNameDone
                                                    }
                                                  >
                                                    {" "}
                                                    <img
                                                      src={DoneIcon}
                                                      alt=""
                                                    ></img>{" "}
                                                    Done
                                                  </span>
                                                )}
                                                {/* {invalidStatus(data?.status) && (
                                                <span
                                                  className={styles.badgeName}
                                                >
                                                  {data?.status}
                                                </span>
                                              )} */}
                                              </>
                                            )}
                                        </td>
                                        {context?.selectedResourceType ===
                                          "In Progress" && (
                                          <td
                                            className={clsx(
                                              styles.snap_table_data__Class,
                                              styles.snapshot_col_weight,
                                              styles.snapshot_col_end
                                            )}
                                            style={{ textAlign: "left" }}
                                          >
                                            {" "}
                                            <span className={styles.badgeName}>
                                              {" "}
                                              {data?.ticket_status !== null
                                                ? data?.ticket_status || "--"
                                                : "NA"}
                                            </span>
                                          </td>
                                        )}

                                        {context?.selectedResourceType !==
                                          "In Progress" && (
                                          <td
                                            className={clsx(
                                              styles.snap_table_data__Class,
                                              styles.snapshot_col_weight
                                            )}
                                          >
                                            {/* {!invalidStatus(data?.status) &&
                                            data?.status !== "done" &&
                                            Boolean(data?.isLegallyHeld) ===
                                              false
                                               && ( */}
                                            <Dropdown
                                              style={{ marginTop: "-6px" }}
                                              show={dropdownOpen[index]}
                                              onToggle={() =>
                                                handleDropdownToggle(index)
                                              }
                                            >
                                              <Dropdown.Toggle
                                                className={
                                                  styles.dropdown_toggle
                                                }
                                                variant="link"
                                                id={`dropdown-basic-${index}`}
                                                style={{
                                                  background: "none",
                                                  border: "none",
                                                  padding: 0,
                                                  marginTop: "5px",
                                                }}
                                              >
                                                {(context?.selectedItems || [])
                                                  .length < 1 && (
                                                  <img
                                                    src={menuicon}
                                                    alt=""
                                                  ></img>
                                                )}
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu
                                                id={styles.dropdown_menu_item}
                                                style={{
                                                  border: "none",
                                                  boxShadow:
                                                    "0 19px 14px rgba(0, 0, 0, 0.1)",
                                                }}
                                              >
                                                {/* {data?.status == "open" && (
                                                    <>
                                                      <Dropdown.Item
                                                        id={
                                                          styles.dropdown_item
                                                        }
                                                        onClick={() => {
                                                          handleModal(
                                                            data?.snapshotId,
                                                            data?.snapshotId,
                                                            data?.history
                                                          );
                                                        }}
                                                      >
                                                        <span
                                                          className={
                                                            styles.dropdown_names_css
                                                          }
                                                        >
                                                          Accept
                                                        </span>
                                                      </Dropdown.Item>
                                                      <Dropdown.Item
                                                        id={
                                                          styles.dropdown_item
                                                        }
                                                        onClick={() =>
                                                          handleArchiveModal(
                                                            data?.snapshotId,
                                                            data?.snapshotId,
                                                            data?.history
                                                          )
                                                        }
                                                      >
                                                        <span
                                                          className={
                                                            styles.dropdown_names_css
                                                          }
                                                        >
                                                          Archive
                                                        </span>
                                                      </Dropdown.Item>
                                                    </>
                                                  )} */}
                                                {data?.status === "active" && (
                                                  <>
                                                    <Dropdown.Item
                                                      id={styles.dropdown_item}
                                                      onClick={() => {
                                                        handleShowJiraModal(
                                                          data
                                                        );
                                                        // context?.setSelectedItems((prev) => [...prev, { ...data, index }]);
                                                      }}
                                                    >
                                                      <span
                                                        className={
                                                          styles.dropdown_names_css
                                                        }
                                                      >
                                                        Accept via Jira
                                                      </span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                      id={styles.dropdown_item}
                                                      onClick={() => {
                                                        handleShowRetainModal(
                                                          data
                                                        );
                                                        // context?.setSelectedItems((prev) => [...prev, { ...data, index }]);
                                                      }}
                                                    >
                                                      <span
                                                        className={
                                                          styles.dropdown_names_css
                                                        }
                                                      >
                                                        Retain
                                                      </span>
                                                    </Dropdown.Item>
                                                  </>
                                                )}

                                                {data?.status == "accepted" && (
                                                  <>
                                                    <Dropdown.Item
                                                      id={styles.dropdown_item}
                                                      onClick={() => {
                                                        acceptMutate({
                                                          resourceId:
                                                            data?.snapshotId,
                                                          status: "inprogress",
                                                          service: service,
                                                        });
                                                      }}
                                                    >
                                                      <span
                                                        className={
                                                          styles.dropdown_names_css
                                                        }
                                                      >
                                                        Approve
                                                      </span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                      id={styles.dropdown_item}
                                                      onClick={() =>
                                                        handleDiscardModal(
                                                          data?.snapshotId,
                                                          data?.snapshotId,
                                                          data?.history
                                                        )
                                                      }
                                                    >
                                                      <span
                                                        style={{
                                                          color: "#D01A1A",
                                                          fontWeight: "500",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        Discard
                                                      </span>
                                                    </Dropdown.Item>
                                                  </>
                                                )}

                                                {data?.status ==
                                                  "inprogress" && (
                                                  <Dropdown.Item
                                                    id={styles.dropdown_item}
                                                    onClick={() => {
                                                      acceptMutate({
                                                        resourceId:
                                                          data?.snapshotId,
                                                        status: "done",
                                                        service: service,
                                                      });
                                                    }}
                                                  >
                                                    <span>Done</span>
                                                  </Dropdown.Item>
                                                )}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                            {/* )} */}
                                          </td>
                                        )}
                                      </>
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <Pagination totalItems={data?.total || 0} />
                    </>
                  )}
              </>
            </div>

            {context.eventkey === "Active" &&
              showFilterSection &&
              !hideFilterSection && (
                <FilterSection service={service} name={name} type="active" />
              )}
          </div>
          {showCopyMessage && (
            <div className={styles.copyMessage}>Copy to Clipboard!</div>
          )}
        </>
      )}

      {/* <Offcanvas
        placement="end"
        show={ShowModal}
        onHide={() => {
          setShowModal(false);
        }}
        style={{
          width: "45%",
        }}
      >
        <Offcanvas.Body
          className={styles.modal_body}
          style={{
            padding: "0px",
            justifyContent: "space-between",
          }}
        >
          <SnapModal actionType={actionType} setShowModals={setShowModal} />
        </Offcanvas.Body>
      </Offcanvas> */}
      <AcceptViaJira
        show={context?.showJiraModal}
        handleClose={handleCloseJiraModal}
        name="Snapshots"
        type={name}
        selectedRows={context?.selectedItems}
        updateStatusPending={jiraPendingReq}
        onSubmit={(data) => {
          acceptMutatejira({
            service,
            status: "accept",
            comment: data.comment,
            resources: selectedResourcesModified,
          });
        }}
      />
      <RetainModal
      type={name}
        show={context?.showRetainModal}
        handleClose={handleCloseRetainModal}
        name={"Snapshots"}
        selectedRows={context?.selectedItems}
        updateStatusPending={jiraPendingReq}
        onSubmit={(data) => {
          acceptMutatejira({
            service,
            status: "retained",
            comment: data.comment,
            resources: selectedResourcesModified,
          });
        }}
      />
      <ViewAcceptData
      type={name}
        show={context?.acceptViaJira}
        handleClose={handleClosecceptmodal}
        name="Snapshots"
        selectedRows={jiraData?.data}
        isLoading={jiraModalLoading}
        selectedResourceId={resourceId}
      />

      {/* <Offcanvas
        placement="end"
        show={ShowAcceptModal}
        onHide={() => {
          setShowAcceptModal(false);
        }}
        style={{
          width: "45%",
        }}
      >
        <Offcanvas.Body
          className={styles.modal_body}
          style={{
            padding: "0px",
            justifyContent: "space-between",
          }}
        >
          <ViewAccept actionType={actionType} setShowacceptModals={setShowAcceptModal} />
        </Offcanvas.Body>
      </Offcanvas> */}

      {/**DISCARDED ENTRIES TABLE */}

      {/**MODALS FOR ACCEPT, DISMISS AND ARCHIVE */}

      <AcceptModalComponent
        resourceId={resourceId}
        enable={enableAcceptModal}
        disable={disableModal}
        service={service}
        mutate={acceptMutate}
        data={updateData}
        refetchActiveTable={refetchActiveTable}
        savingid={savingsId}
        historyData={historyData}
        isErrorArchive={isAcceptDismissError}
        checkHistory={checkedHistory}
        name="Snapshot"
        onSubmit={(data) => {
          acceptMutate({
            resourceId: resourceId,
            service,
            status: "accepted",
            acceptData: {
              ticket_id: data.ticket_id,
              ticket_url: data.ticket_url,
            },
            comment: data.comment,
          });
        }}
      />
      <ArchiveModal
        enable={enableArchiveModal}
        resourceId={resourceId}
        service={service}
        disable={handleArchiveModalClose}
        mutate={acceptMutate}
        data={updateData}
        refetchActiveTable={refetchActiveTable}
        savingid={savingsId}
        historyData={historyData}
        checkHistory={checkedHistory}
        isErrorArchive={isAcceptDismissError}
        name="Snapshot"
        onSubmit={(data) => {
          acceptMutate({
            resourceId: resourceId,
            service,
            status: "archived",
            comment: data.comment,
          });
        }}
      />
      <DiscardedModal
        enable={enableDiscardModal}
        disable={handleDiscardedModalClose}
        service={service}
        resourceId={resourceId}
        mutate={acceptMutate}
        data={updateData}
        savingid={savingsId}
        historyData={historyData}
        checkHistory={checkedHistory}
        isErrorArchive={isAcceptDismissError}
        name="Snapshot"
        onSubmit={(data) => {
          acceptMutate({
            resourceId: resourceId,
            service,
            status: "discarded",
            comment: data.comment,
          });
        }}
      />
    </>
  );
};

export default BodySection;
